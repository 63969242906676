/* eslint-disable */

const initState = {
    isSearchHandled: false,
    isSearchStarted: false,
    isSearchsuggestion: false,
    talents: [],
    talentsCount: 0,
    searchFilters: {
        platform: "instagram" as string,
        categories: [] as string[],
        gender: [] as string[],
        age: [] as string[],
        origin: [] as string[],
        residence: [] as string[],
        city: {},
        followersCount: [] as string[],
        engagementRate: [] as string[],
        personReached: [] as string[],
        order_by: "d.followers_count" as string,
        sort_dir: "DESC" as string
    },
    searchHistory: null as unknown
}

export const stepper = {
    namespaced: true,
    state: initState,
    actions: {
    },
    mutations: {
        storeSearchFilters(state: any, filters: any) {
            state.searchFilters = filters;
        },
        storePlatformType(state: any, platform: string) {
            state.searchFilters.platform = platform;
        },
        storeCategories(state: any, categories: string[]) {
            state.searchFilters.categories = categories;
        },
        storeGender(state: any, gender: string[]) {
            state.searchFilters.gender = gender;
        },
        storeAge(state: any, age: string[]) {
            state.searchFilters.age = age;
        },
        storeOrigin(state: any, origin: string[]) {
            state.searchFilters.origin = origin;
        },
        storeResidence(state: any, residence: string[]) {
            state.searchFilters.residence = residence;
        },
        storeCity(state: any, city: any) {
            state.searchFilters.city = city;
        },
        storeFollowersCount(state: any, followersCount: any) {
            state.searchFilters.followersCount = followersCount;
        },
        storeEngagementRate(state: any, engagementRate: any) {
            state.searchFilters.engagementRate = engagementRate;
        },
        storePersonReached(state: any, personReached: any) {
            state.searchFilters.personReached = personReached;
        },
        storeSearchOrder(state: any, orderBy: string) {
            state.searchFilters.order_by = orderBy;
        },
        storeSearchDir(state: any, sortDir: string) {
            state.searchFilters.sort_dir = sortDir;
        },
        startSearch(state: any, isSearchStarted: boolean) {
            state.isSearchStarted = isSearchStarted
        },
        Searchsuggestion(state: any, isSearchsuggestion: boolean) {
            state.isSearchsuggestion = isSearchsuggestion
        },
        handleSearch(state: any, isSearchHandled: boolean) {
            state.isSearchHandled = isSearchHandled
        },
        storeSearchHistory(state: any, filters: any) {
            state.searchHistory = filters;
        },

        resetState(state: any) {
            state.searchFilters.categories = [] as string[]
            state.searchFilters.age = null as unknown
            state.searchFilters.gender = null as unknown
            state.searchFilters.origin = null as unknown
            state.searchFilters.residence = null as unknown
            state.searchFilters.city = null as unknown
            state.searchFilters.followersCount = null as unknown
            state.searchFilters.engagementRate = null as unknown
            state.searchFilters.personReached = null as unknown
            state.searchHistory = null as unknown
            state.talents = [] as any[]
            state.talentsCount = 0;
            state.isSearchHandled = false;
            state.isSearchStarted = false;
            state.isSearchsuggestion = false;

        },
        storeTalents(state: any, talents: any[]) {
            state.talents = talents
        },
        storeTalentsCount(state: any, count: number) {
            state.talentsCount = count
        },
        refindState(state: any) {
            state.isSearchHandled = false
            state.talents = [] as any[]
        }
    },
    getters: {
        getCategories: (state: any) => {
            return state.searchFilters.categories
        },
        getGender: (state: any) => {
            return state.searchFilters.gender
        },
        getAge: (state: any) => {
            return state.searchFilters.age
        },

        getOrigin: (state: any) => {
            return state.searchFilters.origin
        },
        getResidence: (state: any) => {
            return state.searchFilters.residence
        },
        getCity: (state: any) => {
            return state.searchFilters.city
        },
        getFollowersCount: (state: any) => {
            return state.searchFilters.followersCount
        },
        getEngagementRate: (state: any) => {
            return state.searchFilters.engagementRate
        },
        getPersonReached: (state: any) => {
            return state.searchFilters.personReached

        }
    }
}
