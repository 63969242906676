import { io } from "socket.io-client";
import { getConfigValue } from "../core/config";
import { useStore } from "vuex";
import { computed } from "vue";
const BASE_API_URL = getConfigValue('BASE_API_URL');
const NODE_ENV = getConfigValue("NODE_ENV")
const store = useStore();
import md5 from 'md5';

const userInfo = computed(() => store?.state?.global?.userInfo);
export const useSocketIO = () => {
    const socket = io(BASE_API_URL, {
        autoConnect: true
    })

    return {
        socket,
    }
}

export const emitNotification = (the_notification: any) => {
    // const notification_hash = hashNotification(the_notification)
    // const created_at = new Date()
    // const notification= { ...the_notification, notification_hash ,created_at}
    // useSocketIO().socket.emit("new_notification", {
    //     user: userInfo.value,
    //     notification
    // });
    // You may want to handle disconnection as well
    // io.on("disconnect", () => {
    //     console.log('Disconnected from the server');
    // });
}

const hashNotification = (input: any) => {
    const { campaign_id, notification_type, notification_module_, sender_id, receiver_id, module_item_id, sender_role } = input;
    const data = [
        notification_type,
        notification_module_,
        campaign_id,
        sender_id,
        receiver_id,
        module_item_id,
        sender_role,
    ];
    return md5(data.join('::'));
}


// export const useSocketIO = () => {
//     const socket = io(BASE_API_URL, {
//         autoConnect: true
//     })

//     return {
//         socket,
//     }
// }