import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  id: "",
  role: "button"
}
const _hoisted_2 = { class: "multiselect-country" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_multiselect = _resolveComponent("multiselect")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: "",
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.changeStyleFilterSearch()))
    }),
    _createElementVNode("form", {
      id: "",
      onSubmit: _cache[2] || (_cache[2] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.searchHandler && _ctx.searchHandler(...args)), ["prevent"]))
    }, [
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_multiselect, {
          modelValue: _ctx.filters.residence,
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.filters.residence) = $event)),
          mode: "tags",
          options: _ctx.countries,
          searchable: true,
          class: "multiselect-country",
          placeholder: "Select countries",
          "tag-class": 'custom-multiselect-tag'
        }, null, 8, ["modelValue", "options"])
      ])
    ], 32)
  ]))
}