import { getConfigValue } from '@/core/config';
import token_service from '@/core/services/token_service';
//import { before } from 'lodash';
import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
//import { useStore } from "vuex";
const Home = () => import("../pages/Home.vue");
const DiscoverTalents = () => import("@/pages/discover/index.vue");
const SearchByProfile = () => import("@/pages/search-by-profile/index.vue");
const Favorite = () => import("@/pages/favorite/index.vue");
const CampaignList = () => import("@/pages/campaign/index.vue");
const CampaignDetails = () => import("@/pages/campaign/CampaignDetails.vue");
const EditCampaign = () => import("@/pages/campaign/EditCampaign.vue");
const AddCampaign = () => import("@/pages/campaign/AddCampaign.vue")
const SavedShortList = () => import("@/pages/shortlist/SavedShortList.vue");
const ShortListDetails = () => import("@/pages/shortlist/SavedShortListDetails.vue");
const Settings = () => import("@/pages/Settings.vue")
const ProfilePage = () => import("@/pages/Profile.vue")
const ContactPage = () => import("@/pages/contact/contact-us.vue")
const FreemiumUpdateProfile = () => import("@/pages/FreemiumUpdateProfile.vue")

const AccountDetails = () => import("@/pages/influencer-profile-details/index.vue")
const CalculateEngRate = () => import("@/pages/calculate-eng-rate/EngagementRateIndex.vue")
const NotFound = () => import("@/pages/404NotFound.vue")
const Inbox = () => import("@/pages/Inbox/DirectInbox.vue")
const InfluencerOpportunities = () => import("@/pages/opportunity/Opportunities.vue")
const CampaignDetailsForInfluencer = () => import("@/pages/opportunity/OpportunityDetails.vue");
const AdvertiserQuotes = () => import("@/pages/quote/index.vue");
const SavedOpportunities = () => import("@/pages/opportunity/SavedOpportunities.vue")
const InfluencerInvitation = () => import("@/pages/invitation/index.vue")
const InfluencerInvitationDetails = () => import("@/pages/invitation/CampaignInvitationDetails.vue")
const InfluencerApplications = () => import("@/pages/application/InfluencerApplicationsList.vue");
const InfluencerCollaborations = () => import("@/pages/collaboration/CollaborationsList.vue");
const InfluencerApplicationDetails = () => import("@/pages/application/InfluencerApplicationDetails.vue");
const InfluencerContracts = () => import("@/pages/contract/InfluencerContractsList.vue");
const InfluencerBriefs = () => import("@/pages/brief/InfluencerBriefsList.vue");
const InfluencerPlanning = () => import("@/pages/planning/InfluencerPlanning.vue");
const ContentApprovalCampaignsList = () => import("@/pages/content/ContentApprovalCampaignsList.vue");
const InfluencerPerformanceList = () => import("@/pages/performance/InfluencerPerformanceList.vue");
const InfluencerPerformance = () => import("@/pages/performance/InfluencerPerformance.vue");
const InfluncerCampaignsTracking = () => import("@/pages/performance/InfluncerCampaignsTracking.vue");

//CAMPAIGN TIMELINE INFLUENCER
const InfluencerCollaborationDetails = () => import("@/pages/campaign/process-influencer/CollaborationDetails.vue");
const InfluencerCollaborationQuote = () => import("@/pages/campaign/process-influencer/CollaborationQuote.vue");
const InfluencerCollaborationContract = () => import("@/pages/campaign/process-influencer/CollaborationContract.vue");
const InfluencerCollaborationBrief = () => import("@/pages/campaign/process-influencer/CollaborationBrief.vue");
const InfluencerCollaborationPlanning = () => import("@/pages/campaign/process-influencer/CollaborationPlanning.vue");
const InfluencerCollaborationContent = () => import("@/pages/campaign/process-influencer/CollaborationContent.vue");
const InfluencerCollaborationContentOfEvent = () => import("@/pages/campaign/process-influencer/CollaborationContentOfEvent.vue");
const InfluencerCollaborationStatistics = () => import("@/pages/campaign/process-influencer/CollaborationStatistics.vue");
const CollaborationPerformance = () => import("@/pages/campaign/process-influencer/CollaborationPerformance.vue");
const CollaborationTracking = () => import("@/pages/campaign/process-influencer/CollaborationTracking.vue");

const InfluencerCollaborationStatisticsOfEvent = () => import("@/pages/campaign/process-influencer/CollaborationStatisticsOfEvent.vue");
const InfluencerCollaborationStatisticsOfEventMedia = () => import("@/pages/campaign/process-influencer/CollaborationStatisticsOfEventMedia.vue");
const InfluencerNotificationsCenter = () => import("@/pages/notifications/InfluencerNotificationsCenter.vue");

const AdvertiserQuoteDetails = () => import("@/pages/quote/QuoteDetails.vue")
const AdvertiserApplications = () => import("@/pages/application/AdvertiserApplicationsList.vue");
const AdvertiserApplicationDetails = () => import("@/pages/application/AdvertiserApplicationDetails.vue");
const AdvertiserContracts = () => import("@/pages/contract/AdvertiserContractsList.vue");
const AdvertiserBriefs = () => import("@/pages/brief/AdvertiserBriefsList.vue");
const AdvertiserPlanning = () => import("@/pages/planning/AdvertiserPlanning.vue");
const ContentReviewCampaignsList = () => import("@/pages/content/ContentReviewCampaignsList.vue");
const AdvertiserCampaignsTracking = () => import("@/pages/performance/AdvertiserCampaignsTracking.vue");
const AdvertiserCampaignsPerformance= ()=>import("@/pages/performance/AdvertiserCampaignsPerformance.vue")
//CAMPAIGN TIMELINE ADVERTISER

const AdvertiserCampaignQuotes = () => import("@/pages/campaign/process-advertiser/CampaignQuotes.vue");
const AdvertiserCampaignApplications = () => import("@/pages/campaign/process-advertiser/CampaignApplications.vue");
const AdvertiserCampaignContracts = () => import("@/pages/campaign/process-advertiser/CampaignContracts.vue");
const AdvertiserCampaignBriefs = () => import("@/pages/campaign/process-advertiser/CampaignBriefs.vue");
const AdvertiserCampaignPlanning = () => import("@/pages/campaign/process-advertiser/CampaignPlanning.vue");
const AdvertiserCampaignContent = () => import("@/pages/campaign/process-advertiser/CampaignContent.vue");
const AdvertiserCampaignContentOfCreator = () => import("@/pages/campaign/process-advertiser/CampaignCreatorContent.vue");
const AdvertiserCampaignContentOfEvent = () => import("@/pages/campaign/process-advertiser/CampaignContentOfEvent.vue");
const AdvertiserCampaignPerformance = () => import("@/pages/campaign/process-advertiser/CampaignPerformance.vue");
const AdvertiserCampaignTracking = () => import("@/pages/campaign/process-advertiser/CampaignTracking.vue");
const CampignTrackingInfluncers = () => import("@/pages/campaign/process-advertiser/CampignTrackingInfluncers.vue");
const CampignStatisicsnfluncers = () => import("@/pages/campaign/process-advertiser/CampignStatisicsnfluncers.vue");
const AdvertiserPerformance=()=>import ("@/pages/performance/AdvertiserPerformance.vue")
const AdvertiserCampaignStatistics = () => import("@/pages/campaign/process-advertiser/CampaignStatistics.vue");
const AdvertiserCampaignStatisticsPerCreator = () => import("@/pages/campaign/process-advertiser/CampaignCreatorStatistics.vue");
const AdvertiserCampaignStatisticsOfEvent = () => import("@/pages/campaign/process-advertiser/CampaignStatisticsOfEvent.vue");
const AdvertiserCampaignStatisticsOfEventMedia = () => import("@/pages/campaign/process-advertiser/CampaignStatisticsOfEventMedia.vue");
const AdvertiserCampaignQuoteDetails = () => import("@/pages/campaign/process-advertiser/CampaignQuoteDetails.vue");
const AdvertiserCampaignApplicationDetails = () => import("@/pages/campaign/process-advertiser/CampaignApplicationDetails.vue");
const AdvertiserCampaignBriefDetails = () => import("@/pages/campaign/process-advertiser/CampaignBriefDetails.vue");
const AdvertiserCampaignBriefEdit = () => import("@/pages/campaign/process-advertiser/CampaignBriefEdit.vue");
const AdvertiserNotificationsCenter = () => import("@/pages/notifications/AdvertiserNotificationsCenter.vue");
const BrandsList = () => import("@/pages/brands/BrandsList.vue");
const ClientsList = () => import("@/pages/clients/ClientsList.vue");


// console.log(token_service.getRole());

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
const routes: Array<RouteRecordRaw> = [
  // Platform Routes
  {
    path: '/',
    name: 'home',
    component: Home
  },
  {
    path: '/discover',
    name: 'discover',
    component: DiscoverTalents
  },
  {
    path: '/calculate-eng-rate',
    name: 'Calculate engagement rates',
    component: CalculateEngRate,
  },
  {
    path: '/search',
    name: 'search',
    component: SearchByProfile
  },
  {
    path: '/favorite',
    name: 'favorite',
    component: Favorite
  },
  {
    path: '/short-lists',
    name: 'Shortlists',
    component: SavedShortList
  }
  ,
  { //6
    path: '/short-list/:id',
    name: 'ShortListDetails',
    component: ShortListDetails,
  },
  {
    path: '/brands',
    name: 'Brands',
    component: BrandsList
  },
  {
    path: '/clients',
    name: 'Clients',
    component: ClientsList
  },
  {
    path: '/campaigns',
    name: 'Campaign agency',
    component: CampaignList
  },
  {
    path: '/quotes',
    name: 'AdvertiserQuotes',
    component: AdvertiserQuotes
  },
  {
    path: '/applications',
    name: 'AdvertiserApplications',
    component: AdvertiserApplications
  },
  {
    path: '/contracts',
    name: 'AdvertiserContracts',
    component: AdvertiserContracts
  },
  {
    path: '/briefs',
    name: 'AdvertiserBriefs',
    component: AdvertiserBriefs
  },
  {
    path: '/planning',
    name: 'AdvertiserPlanning',
    component: AdvertiserPlanning
  },
  {
    path: '/my-contracts',
    name: 'InfluencerContracts',
    component: InfluencerContracts
  },
  {
    path: '/my-applications',
    name: 'InfluencerApplications',
    component: InfluencerApplications
  },
  {
    path: '/my-briefs',
    name: 'InfluencerBriefs',
    component: InfluencerBriefs
  },
  {
    path: '/my-planning',
    name: 'InfluencerPlanning',
    component: InfluencerPlanning
  },
  {
    path: '/setting',
    name: 'setting',
    component: Settings
  },
  {
    path: '/profile',
    name: 'profile',
    component: ProfilePage
  },
  {
    path: '/FreemiumUpdateProfile',
    name: 'FreemiumUpdateProfile',
    component: FreemiumUpdateProfile
  },
  {
    path: '/contact-us',
    name: 'contact',
    component: ContactPage
  },
  {
    path: '/direct/inbox',
    name: 'inbox',
    component: Inbox
  },
  {
    path: '/campaign/add',
    name: 'campaignCreation',
    component: AddCampaign
  },
  {
    path: '/campaign/:campaign_id',
    name: 'campaignDetails',
    component: CampaignDetails
  },
  {
    path: '/campaign/:campaign_id/quotes',
    name: 'AdvertiserCampaignQuotes',
    component: AdvertiserCampaignQuotes
  },
  {
    path: '/campaign/:campaign_id/applications',
    name: 'AdvertiserCampaignApplications',
    component: AdvertiserCampaignApplications
  },
  {
    path: '/campaign/:campaign_id/contracts',
    name: 'AdvertiserCampaignContracts',
    component: AdvertiserCampaignContracts
  },
  {
    path: '/campaign/:campaign_id/briefs',
    name: 'AdvertiserCampaignBriefs',
    component: AdvertiserCampaignBriefs
  },
  {
    path: '/campaign/:campaign_id/planning',
    name: 'AdvertiserCampaignPlanning',
    component: AdvertiserCampaignPlanning
  },
  {
    path: '/campaign/:campaign_id/content',
    name: 'AdvertiserCampaignContent',
    component: AdvertiserCampaignContent
  },
  {
    path: '/campaign/:campaign_id/content/:collaboration_id',
    name: 'AdvertiserCampaignContentOfCreator',
    component: AdvertiserCampaignContentOfCreator
  },
  {
    path: '/campaign/:campaign_id/content/:content_id/event/:event_id',
    name: 'AdvertiserCampaignContentOfEvent',
    component: AdvertiserCampaignContentOfEvent
  },
  {
    path: '/campaign/:campaign_id/performance',
    name: 'AdvertiserCampaignPerformance',
    component: AdvertiserCampaignPerformance
  },
  {
    path: '/campaign/:campaign_id/performance/track',
    name: 'AdvertiserCampaignTracking',
    component: AdvertiserCampaignTracking
  },
  {
    path: '/campaign/:campaign_id/performance/track/influncers',
    name: 'CampignTrackingInfluncers',
    component: CampignTrackingInfluncers
  },
  {
    path: '/campaign/:campaign_id/performance/track/influncers/stats',
    name: 'CampignStatisicsnfluncers',
    component: CampignStatisicsnfluncers
  },
  {
    path: '/campaign/:campaign_id/performance/stats',
    name: 'AdvertiserCampaignStatistics',
    component: AdvertiserCampaignStatistics
  },
  {
    path: '/campaign/:campaign_id/collaboration/performance/:collaboration_id',
    name: 'AdvertiserCampaignStatisticsPerCreator',
    component: AdvertiserCampaignStatisticsPerCreator
  },
  {
    path: '/campaign/:campaign_id/performance/:event_id',
    name: 'AdvertiserCampaignStatisticsOfEvent',
    component: AdvertiserCampaignStatisticsOfEvent
  },
  {
    path: '/campaign/:campaign_id/performance/:event_id/media/:content_media_id',
    name: 'AdvertiserCampaignStatisticsOfEventMedia',
    component: AdvertiserCampaignStatisticsOfEventMedia
  },
  {
    path: '/campaign/:campaign_id/quotes/:quote_id',
    name: 'AdvertiserCampaignQuoteDetails',
    component: AdvertiserCampaignQuoteDetails
  },
  {
    path: '/campaign/:campaign_id/applications/:application_id',
    name: 'AdvertiserCampaignApplicationDetails',
    component: AdvertiserCampaignApplicationDetails
  },
  {
    path: '/campaign/:campaign_id/briefs/:brief_id',
    name: 'AdvertiserCampaignBriefDetails',
    component: AdvertiserCampaignBriefDetails
  },
  {
    path: '/campaign/:campaign_id/briefs/:brief_id/edit',
    name: 'AdvertiserCampaignBriefEdit',
    component: AdvertiserCampaignBriefEdit
  },
  {
    path: '/collaboration/:collaboration_id',
    name: 'InfluencerCollaborationDetails',
    component: InfluencerCollaborationDetails
  },
  {
    path: '/collaboration/:collaboration_id/quote',
    name: 'InfluencerCollaborationQuote',
    component: InfluencerCollaborationQuote
  },
  {
    path: '/collaboration/:collaboration_id/contract',
    name: 'InfluencerCollaborationContract',
    component: InfluencerCollaborationContract
  },
  {
    path: '/collaboration/:collaboration_id/brief',
    name: 'InfluencerCollaborationBrief',
    component: InfluencerCollaborationBrief
  },
  {
    path: '/collaboration/:collaboration_id/planning',
    name: 'InfluencerCollaborationPlanning',
    component: InfluencerCollaborationPlanning
  },
  {
    path: '/collaboration/:collaboration_id/content',
    name: 'InfluencerCollaborationContent',
    component: InfluencerCollaborationContent
  },
  {
    path: '/collaboration/:collaboration_id/content/:content_id/event/:event_id',
    name: 'InfluencerCollaborationContentOfEvent',
    component: InfluencerCollaborationContentOfEvent
  },
  {
    path: '/collaboration/:collaboration_id/performance/stats',
    name: 'InfluencerCollaborationStatistics',
    component: InfluencerCollaborationStatistics
  },
  {
    path: '/collaboration/:collaboration_id/performance/track',
    name: 'CollaborationTracking',
    component: CollaborationTracking
  },
  {
    path: '/collaboration/:collaboration_id/performance',
    name: 'CollaborationPerformance',
    component: CollaborationPerformance
  },
  {
    path: '/collaboration/:collaboration_id/performance/:event_id',
    name: 'InfluencerCollaborationStatisticsOfEvent',
    component: InfluencerCollaborationStatisticsOfEvent
  },
  {
    path: '/collaboration/:collaboration_id/performance/:event_id/media/:content_media_id',
    name: 'InfluencerCollaborationStatisticsOfEventMedia',
    component: InfluencerCollaborationStatisticsOfEventMedia
  },
  {
    path: '/quote/:quote_id',
    name: 'AdvertiserQuoteDetails',
    component: AdvertiserQuoteDetails
  },
  {
    path: '/application/:application_id',
    name: 'ApplicationDetails',
    component: AdvertiserApplicationDetails
  },
  {
    path: '/my-application/:application_id',
    name: 'InfluencerApplicationDetails',
    component: InfluencerApplicationDetails
  },
  {
    path: '/collaborations',
    name: 'InfluencerCollaborations',
    component: InfluencerCollaborations
  },
  {
    path: '/opportunity/:campaign_id',
    name: 'campaignDetailsForInfluencer',
    component: CampaignDetailsForInfluencer
  },
  {
    path: '/campaign/:campaign_id/edit',
    name: 'editCampaign',
    component: EditCampaign,
  },
  {
    path: '/opportunities',
    name: 'InfluencerOpportunities',
    component: InfluencerOpportunities,
  },
  {
    path: '/saved-opportunities',
    name: 'savedOpportunities',
    component: SavedOpportunities,
  },
  {
    path: '/invitations',
    name: 'influencerInvitation',
    component: InfluencerInvitation,
  },
  {
    path: '/invitation/:quote_id',
    name: 'influencerInvitationDetails',
    component: InfluencerInvitationDetails,
  },
  {
    path: '/content-approval',
    name: 'ContentApprovalCampaignsList',
    component: ContentApprovalCampaignsList,
  },
  {
    path: '/content-review',
    name: 'ContentReviewCampaignsList',
    component: ContentReviewCampaignsList,
  },
  {
    path: '/my-performance',
    name: 'InfluencerPerformance',
    component: InfluencerPerformance,
  },
  {
    path: '/my-performance/stats',
    name: 'InfluencerPerformanceList',
    component: InfluencerPerformanceList,
  },
  {
    path: '/my-performance/campaign',
    name: 'InfluncerCampaignsTracking',
    component: InfluncerCampaignsTracking,
  },
  {
    path: '/performance',
    name: 'AdvertiserCampaignsTracking',
    component: AdvertiserCampaignsTracking,
  },
  {
    path: '/performance/campaign',
    name: 'AdvertiserPerformance',
    component: AdvertiserPerformance,
  },
  {
    path: '/performance/stats',
    name: 'AdvertiserCampaignsPerformance',
    component: AdvertiserCampaignsPerformance,
  },
  {
    path: '/notifications',
    name: 'AdvertiserNotificationsCenter',
    component: AdvertiserNotificationsCenter,
  },
  {
    path: '/notifications-center',
    name: 'InfluencerNotificationsCenter',
    component: InfluencerNotificationsCenter,
  },
  {
    path: '/influencer/:influencer_id',
    name: 'influencerDetails',
    component: AccountDetails
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'NotFound',
    component: NotFound
  },
];

getConfigValue("CHAT") === 'true' ? routes : routes.filter(r => r.name !== 'inbox');
getConfigValue("SHORTLIST") === 'true' ? routes : routes.filter(r => r.name !== 'ShortListDetails');

const router = createRouter({
  history: createWebHistory(),
  routes,
});


//----------------------------------------
// the route guarder
/*router.beforeEach(async (_to, _from, next) => {
  if (token_service.getToken() || new URLSearchParams(window.location.search).has("token")) {

    // console.log('_to', _to)

    store.watch((state: any) => state.global, (data) => {
      isValidated = data.isValidated
    }, {
      deep: true
    })
    // is validated route guarder
    if (_to.fullPath !== '/' && !isValidated) {
      if ((isValidated === false) && ((_to.fullPath !== '/')=== false)) {
        //next()
      }else{
        // console.log("if user is not validated and don't go to home");
        //await router.push({ path: "/" })
      }
    }
    // talent route guarder
    if (isValidated) {
      if (
        (token_service.getRole() === "TALENT") &&
        (_to.fullPath === '/discover' || _to.fullPath === '/search' || _to.fullPath === '/favorite' || _to.fullPath === '/short-lists')
      ) {
        // console.log("user is validated and Talent role , he must push back to home ");

        //await router.push({ path: "/" })
      }
    }
    next()

  } else {
    window.location.replace(window.location.protocol + "//" + "localhost:8000/login")
  }
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  //// console.log('Has access to the page', ability.possibleRulesFor(['Profile']))
})*/

//plugins

//const { can } = useAbility()
//const store = useStore()
/*
  *** route guarder
*/
/*router.beforeEach((to,from,next)=> {
  //const rules = store.state.global.rules
  //// console.log("user rules : ", rules);

  const isLoggedIn = token_service.getToken()
  if (!isLoggedIn) {
    //window.location.replace("localhost:8000/login")
  } else {
    //const isValidated = store.state?.global.isValidated
    //// console.log("isValidated : ",isValidated);


  }
})*/

router.beforeEach(async (_to, _from, next) => {
  // console.log(_to);
  // console.log(_from);

  if (_to.path === '/') {
    let isAdvertiser: boolean
    setTimeout(async () => {
      isAdvertiser = (token_service.getRole() === 'AGENCY' || token_service.getRole() === 'BRAND') ? true : false
      // console.log(`is agency : ${isAdvertiser}`);

      if (isAdvertiser) {
        //_to.path = '/discover'
        //await next('/discover')
        await router.push('/')
      } else {
        await router.push('/')
      }
    }, 200);
  }
  /*const agencyRoleRequired = _to.matched.some(
    ({ name }) => name === 'discovertalents'
  )
  const _role = token_service.getUser().role ;
  if (agencyRoleRequired && _role === "AGENCY") {
    next()
  }else{
    next("/home")
  }*/
  next()
})


export default router
