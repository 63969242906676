
import {defineComponent, ref, watch} from "vue";
import { useStore } from "vuex";
import OptionsInput from "@/components/form/OptionsInput.vue";
import { genders } from '@/core/common/steps'

export default defineComponent({
  name: "GenderStep",
  components: {
    OptionsInput
  },
  setup() {
    const store = useStore()
    const state = store.state
    const selectedOptions = ref(state.stepper.searchFilters.gender)

    watch(selectedOptions, () => {
      store.commit("stepper/storeGender", selectedOptions.value)
    }, {
      deep: true
    })

    return {
      genders,
      selectedOptions
    }
  }
});
