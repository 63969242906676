
import {defineComponent, ref, unref, watch, Ref, PropType} from "vue";

export default defineComponent({
  name: "OptionsInput",
  components: {},
  props: {
    items: {
      type: Array as PropType<any[]>,
      required: true,
      default: () => []
    },
    modelValue: {
      type: Array as PropType<string[]>,
      required: true,
      default: () => []
    },
    classes: {
      type: String,
      default: () => null
    }
  },
  setup(props, { emit }) {
    const selectedOptions: Ref<string[]> = ref(props.modelValue ? props.modelValue : [])

    const isCheck = (option: string) => {
      return selectedOptions.value.includes(option)
    }

    watch(selectedOptions, () => {
      emit('update:modelValue', unref(selectedOptions));
    }, {
      deep: true
    })

    return {
      selectedOptions,
      isCheck
    }
  }
});
