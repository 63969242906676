
import { defineComponent } from "vue";

export default defineComponent({
  name: "NotificationItemComponent",
  props: {
    name: String,
    subname: String,
    imgUrl: String,
    lastUpdate: String,
    isReaden: Boolean,
  },
  setup() {
    return {};
  },
  data() {
    return {};
  },
  methods: {},
});
