
import moment from "moment";
import { computed, defineComponent, ref } from "vue";
import { mapState, useStore } from "vuex";
import ContactUsModal from "@/pages/contact-us/ContactUsModal.vue";
import { getConfigValue } from "@/core/config";


//import moment from "moment";

export default defineComponent({
  name: "DaysAndRequestsShadowCardsComponent",
  components: {
    ContactUsModal,
  },
  setup() {
    const store = useStore();
    // refs
    const free_trial_expire_date = ref("");
    const account_validity_from = ref("");
    const max_search_count = ref(undefined);
    const search_request = ref(undefined);
    const isSearchHandled = ref();
    const openContactModal = ref(false);
    const theContactSubject = ref('');
    const theContactCategory = ref('Contact')
    // const isContactActive = getConfigValue("CONTACT") === "true";

    const userInfo = computed(() => {
      return store.state.global.userInfo;
    });
    const is_tawa_agency_email = (): boolean => {
      let result = false
      const tawa_emails = getConfigValue("TAWA_AGENCY_EMAILS")?.split(',') || []
      const email = userInfo.value.user_email
      if (tawa_emails.includes(email)) {
        result = true
      } else if (userInfo.value.advertiser && tawa_emails.includes(userInfo.value.advertiser.advertiser_email)) {
        result = true
      }
      return result
    }
    //computed
    const read_account_validity_to = computed(() => {
      return store.state.quota.free_trial_expire_date;
    });
    const read_account_validity_from = computed(() => {
      //account_validity_from.value = store.state.quota.account_validity_from;
      return store.state.quota.account_validity_from;
    });
    const read_search_request = computed(() => {

      //search_count.value = store.state.quota.search_count;
      return store.state.quota.search_request;
    });
    const read_max_search_count = computed(() => {
      //max_search_count.value = store.state.quota.max_search_count;
      return store.state.quota.max_search_count;
    });
    const reqLeftBarActive = computed(() => {
      return {
        width:
          ((read_search_request.value || 0) /
            (read_max_search_count.value || 12)) *
          100 +
          "%",
        height: 8 + "px",
        "border-radius": 4 + "px",
        "background-color": "#8edc55",
        "overflow": 'hidden',
      };
    });

    const getAllDays = computed(() => {
      let firstDate = moment(read_account_validity_from.value)
      let deadline = moment(read_account_validity_to.value)

      return deadline.diff(firstDate, 'days')
    });

    const getRemainedDays = computed(() => {
      const date = new Date();
      let currentDate = moment([`${date.getFullYear()}`, `${date.getMonth()}`, `${date.getDate()}`])
      let deadline = moment(read_account_validity_to.value)

      return deadline.diff(currentDate, 'days');
    });

    const timeLeftBarActive = computed(() => {
      const remainedDays = getRemainedDays.value;
      const days_count = getAllDays.value;

      return {
        width: 100 + "%",
        height: 8 + "px",
        "border-radius": 4 + "px",
        "background-color": "#fc445d",
        "overflow": 'hidden',
      };
    });

    // watchers
    store.watch(
      (state) => state.stepper.isSearchHandled,
      (_isSearchHandled) => {
        // console.log(" watch isSearchHandled : ", _isSearchHandled);
        isSearchHandled.value = _isSearchHandled;
      }
    );

    store.watch(
      (state) => state.quota.account_validity_from,
      (_account_validity_from) => {
        // console.log(" watch _account_validity_from : ", _account_validity_from);
        account_validity_from.value = read_account_validity_from.value;
      }
    );

    store.watch(
      (state) => state.quota.free_trial_expire_date,
      (_free_trial_expire_date) => {
        // console.log(
        //   " watch _free_trial_expire_date : ",
        //   _free_trial_expire_date
        // );
        free_trial_expire_date.value = read_account_validity_to.value;
      },
      {
        deep: true,
      }
    );

    store.watch(
      (state) => state.quota.max_search_count,
      (_max_search_count) => {
        // console.log(" watch _max_search_count : ", _max_search_count);
        max_search_count.value = read_max_search_count.value;
      },
      {
        deep: true,
      }
    );

    store.watch(
      (state) => state.quota.search_request,
      (_search_count) => {
        // console.log(" watch _search_count : ", _search_count);
        search_request.value = read_search_request.value;
      },
      {
        deep: true,
      }
    );
    // functions
    const resetFilters = () => {
      store.commit("stepper/resetState");
    };
    const refind = () => {
      store.commit("stepper/refindState");
    };
    const logger = () => {
      //window.location.href = "mailto:support@tawa.digital;subject:more_requests";

      const subject = "More requests demand"
      const mailto = "support@tawa.digital"
      const body = `Hello Tawa Digital,
                    Please, offer me more requests 
                    Thanks.`

      window.location.href = `mailto:${mailto}?subject=${subject}&amp;body= ${body} `;

    }

    const showContactModal = () => {
      if (openContactModal.value === true) {
        openContactModal.value = false;
      } else {
        openContactModal.value = true;
      }
    };

    const moreRequests = () => {
      theContactSubject.value = 'More Requests'
      theContactCategory.value = 'Requests'
      showContactModal();
    }

    const moreTrial = () => {
      theContactSubject.value = 'More Time'
      theContactCategory.value = 'Time'
      showContactModal();
    }

    return {
      reqLeftBarActive,
      timeLeftBarActive,
      getRemainedDays,
      getAllDays,
      resetFilters,
      refind,
      logger,
      read_account_validity_to,
      read_account_validity_from,
      read_search_request,
      read_max_search_count,
      search_request,
      max_search_count,
      isSearchHandled,
      moreRequests,
      moreTrial,
      openContactModal,
      showContactModal,
      theContactSubject,
      theContactCategory,
      userInfo,
      is_tawa_agency_email
    };
  },

  computed: {
    ...mapState({
      isSearchHandled: (state: any) => state.stepper.isSearchHandled,
      tags: (state: any) => state.stepper.tags,
      gender: (state: any) => state.stepper.gender,
      age: (state: any) => state.stepper.age,
      origin: (state: any) => state.stepper.origin,
      residence: (state: any) => state.stepper.residence,
      followersCount: (state: any) => state.stepper.followersCount,
      city: (state: any) => state.stepper.city,
      engagementRate: (state: any) => state.stepper.engagementRate,
      personReached: (state: any) => state.stepper.personReached,
    }),
  },

  /* methods: {
    logger() {
      alert("logger ")
      window.location.href = "mailto:support@tawa.digital";
    },
  }, */
});
