import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import setupInterceptors from './core/services/interceptor';
import VueApexCharts from "vue3-apexcharts";
import InlineSvg from 'vue-inline-svg';
import Select2 from 'vue3-select2-component';
import { abilitiesPlugin } from '@casl/vue';
import { ability } from '@/core/services/ability';
import vfmPlugin from 'vue-final-modal'
import VueSidebarMenu from 'vue-sidebar-menu'
import 'vue-sidebar-menu/dist/vue-sidebar-menu.css'
//import Toaster from '@meforma/vue-toaster';
import './registerServiceWorker'


setupInterceptors(store)

createApp(App)
    .use(VueApexCharts)
    .use(store)
    .use(router)
    .use(vfmPlugin)
    .use(VueSidebarMenu)
    .component('inline-svg', InlineSvg)
    .component('Select2', Select2)
    .use(abilitiesPlugin, ability)
    /* .use(Toaster) */
    .mount('#app')

