
import {defineComponent, ref, unref, watch} from "vue";
import {useStore} from "vuex";
import { countries } from '@/core/common/steps'
import CountryInput from "@/components/form/CountryInput.vue";

export default defineComponent({
  name: "ResidenceStep",
  components: {
    CountryInput
  },
  setup() {
    const store = useStore()
    const state = store.state
    const selectedCountries = ref(state.stepper.searchFilters.residence)

    const isCheck = (countryCode: string) => {
      return selectedCountries.value.includes(countryCode)
    }

    watch(selectedCountries, () => {
      store.commit("stepper/storeResidence", unref(selectedCountries));
      store.commit("stepper/storeCity", {})
    }, {
      deep: true
    })

    return {
      countries,
      selectedCountries,
      isCheck
    }
  }
});
