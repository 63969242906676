import axios from "axios";
import { getConfigValue } from '@/core/config';

const BASE_API_URL = getConfigValue('BASE_API_URL');
const NODE_ENV = getConfigValue('NODE_ENV');
// console.log(`BASE_API_URL :>> ${BASE_API_URL}`);
// console.log(`NODE_ENV :>> ${NODE_ENV}`);
const contact = getConfigValue('CONTACT') === 'true';
const chat = getConfigValue('CHAT') === 'true'
const shortlist = getConfigValue('SHORTLIST') === 'true'

// console.log(`contact :>> ${contact}`);
// console.log(`chat :>> ${chat}`);
// console.log(`shortlist :>> ${shortlist}`);


const apiV2BaseUrl = `${String(BASE_API_URL).replace('/v2', '')}/v2`;
// console.log(` apiV2BaseUrl :>> ${apiV2BaseUrl}`);

const instance = axios.create({
    baseURL: apiV2BaseUrl,
    headers: {
        "Content-Type": "application/json",
    },
});

export default instance
