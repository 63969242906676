import { createStore } from 'vuex'
import { global } from "./global.moduleStore";
import { stepper } from "./stepper.moduleStore";
import { quota } from "./quota.moduleStore";
import { engRate } from "./eng_rate.moduleStore";
import { chat } from "./chat.moduleStore";
import { shortList } from "./short_list.moduleStore";
import { campaignFilterStepper } from "./influencer.campaign.advancedFilters.moduleStore";
import { campaignAdvertiserEdit } from "./campaign.advertiser.edit.addTalent.moduleStore";

const store = createStore({
  modules: {
    global,
    stepper,
    quota,
    engRate,
    chat,
    shortList,
    campaignFilterStepper,
    campaignAdvertiserEdit
  }
})

export default store
