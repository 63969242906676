import token_service from "../services/token_service";

export default function useValidators() {

    const isEmpty = (fieldName, fieldValue) => {
        switch (fieldName) {
            case 'fullname':
                fieldName = token_service.getRole() === 'TALENT' ? 'fullname' : 'brand name'
                break;
            case "business_sector_id":
                fieldName = "business sector";
                break;
            case "city":
                fieldName = "city";
                break;
            case "country":
                fieldName = "country";
                break;
            case "country_of_origin":
                fieldName = "origin country";
                break;
            case "firstname":
                fieldName = "first name";
                break;
            case "lastname":
                fieldName = "last name";
                break;
            case "age_intervale":
                fieldName = "age interval";
                break;
            case "username":
                fieldName = "instagram or tiktok username";
                break;

            case "gender":
                fieldName = "Gender";
                break;
            case "phone":
                fieldName = "number phone";
                break;
            case "profile_efile_id":
                fieldName = "profile picture";
                break;
            case "agency_name":
                fieldName = "brand name";
                break;
            case "new_password":
                fieldName = "new password";
                break;
            case "old_password":
                fieldName = "password";
                break;
            case "subject":
                fieldName = "subject";
                break;
            case "body":
                fieldName = "body";
                break;

            default:
                break;
        }
        return !fieldValue || fieldValue === null || fieldValue === undefined || fieldValue.length === 0 ? "The " + fieldName + " field is required" : "";
    }

    const minLength = (fieldName, fieldValue, min) => {
        switch (fieldName) {
            case 'old_password':
                fieldName = 'password'
                break;
            case 'new_password':
                fieldName = 'new password'
                break;

            default:
                break;
        }
        return fieldValue.length < min ? `The ${fieldName} field must be atleast ${min} characters long` : "";
    }

    const isEmail = (fieldName, fieldValue) => {
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return !re.test(fieldValue) ? "The input is not a valid " + fieldName + " address" : "";
    }

    const isNum = (fieldName, fieldValue) => {
        const isNum = /^\d+$/.test(fieldValue);

        return !isNum ? "The " + fieldName + " field only have numbers" : "";
    }

    const isPassword = (fieldName, fieldValue) => {
        const isPassword = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(.{6,15})$/.test(fieldValue);
        switch (fieldName) {
            case 'old_password':
                fieldName = 'password'
                break;
            case 'new_password':
                fieldName = 'new password'
                break;

            default:
                break;
        }
        return !isPassword ? "The " + fieldName + " field must have at least one uppercase char, one lowercase char, one digit" : ""
    }

    const isConfirm = (new_password_value, confirm_password_value) => {
        return new_password_value !== confirm_password_value ? "The confirm password field must match the new password" : ""
    }



    return { isEmpty, minLength, isEmail, isNum, isPassword, isConfirm }
}
