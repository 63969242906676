import { reactive } from "@vue/reactivity";
import useValidators from '@/core/validations/validators'

const errors = reactive({});
export default function useFormValidation() {
    const { isEmpty, minLength, isEmail, isPassword, isNum, isConfirm } = useValidators();

    const validateNameField = (fieldName, fieldValue) => {
        !fieldValue || fieldValue === null || fieldValue === undefined || fieldValue.length === 0 ?
            errors[fieldName] = isEmpty(fieldName, fieldValue) : delete errors[fieldName];

    }
    const validateUsernameField = (fieldName, fieldValue) => {
        !fieldValue || fieldValue === null || fieldValue === undefined || fieldValue.length === 0 ?
            errors['username'] = isEmpty('username', fieldValue) : delete errors['username'];
    }
    const validateEmailField = (fieldName, fieldValue) => {
        errors[fieldName] = !fieldValue ? isEmpty(fieldName, fieldValue) : isEmail(fieldName, fieldValue)
    }

    const validatePhoneField = (fieldName, fieldValue) => {
        errors[fieldName] = !fieldValue ? isEmpty(fieldName, fieldValue) : isNum(fieldName, fieldValue)
    }

    const validatePasswordField = (fieldName, fieldValue) => {
        if (!fieldValue || fieldValue === null || fieldValue === undefined) {
            errors[fieldName] = isEmpty(fieldName, fieldValue)
        } else if (fieldValue.length > 0 && fieldValue.length < 6) {
            errors[fieldName] = minLength(fieldName, fieldValue, 6)
        } else if (fieldValue.length >= 6) {
            errors[fieldName] = isPassword(fieldName, fieldValue)
            if (errors[fieldName] === "") {
                delete errors[fieldName]
            }
        }
    }

    const validateConfirmPasswordField = (new_password_value, confirm_password_value) => {
        if (!confirm_password_value ||
            confirm_password_value === null ||
            confirm_password_value === undefined ||
            confirm_password_value.length === 0) {
            errors['confirm_password'] = 'The confirm password field is required'
        } else if (confirm_password_value === new_password_value) {
            delete errors['confirm_password']
        } else {
            errors['confirm_password'] = isConfirm(new_password_value, confirm_password_value)
        }
    }

    return {
        errors,
        validatePasswordField,
        validateUsernameField,
        validateNameField,
        validateEmailField,
        validatePhoneField,
        validateConfirmPasswordField,
        isEmpty,
    }
}
