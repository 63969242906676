import axiosInstance from "./api";
import TokenService from "./token_service";

const setup = (store: any) => {
  axiosInstance.interceptors.request.use(
    (config: any) => {
      const token = TokenService.getToken();
      if (token) {
        config.headers["Authorization"] = 'Bearer ' + token;
        config.headers['Content-Type'] = 'application/json;charset=UTF-8'
         
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );


};

export default setup;