
import { defineComponent, ref, reactive, watch, Ref } from "vue";
import { useStore } from "vuex";
import DeepDiff from "deep-diff";
import {
  genders,
  ages,
  countries,
  followersCount,
  engagementRates,
  personReached,
} from "@/core/common/steps";
import Multiselect from "@vueform/multiselect";
import { set } from "lodash";
import { getConfigValue } from "@/core/config";

export default defineComponent({
  name: "SearchFilters",
  components: {
    Multiselect,
    // FeedBack,
  },
  setup() {
    const store = useStore();
    const { state } = store;
    const searchFilters = ref(state.stepper.searchFilters);
    const selectedCities = ref(state.stepper.searchFilters.city);
    const dropdownCities: Ref<any[]> = ref([]);
    const cities = reactive({});
    const formattedAges: Ref<any[]> = ref([]);
    const colors = ref(["#979797"]);

    const isFeedbackActive = getConfigValue("FEEDBACK") === "true";

    const filters = ref({
      platform: searchFilters.value.platform,
      categories: searchFilters.value.categories,
      gender: searchFilters.value.gender,
      age: searchFilters.value.age,
      residence: searchFilters.value.residence,
      origin: searchFilters.value.origin,
      followersCount: searchFilters.value.followersCount,
      engagementRate: searchFilters.value.engagementRate,
      personReached: searchFilters.value.personReached,
      city: selectedCities.value,
      order_by: searchFilters.value.order_by,
    });
    watch(
      () => filters.value,
      () => {
        store.commit("stepper/storeSearchFilters", filters.value);
      },
      {
        deep: true,
      }
    );

    const resetFilters = () => {
      store.commit("stepper/resetState");
    };

    const searchHandler = () => {
      const difference = DeepDiff.diff(
        { ...filters.value },
        store.state.stepper.searchHistory
      );
      if (
        typeof difference !== "undefined" &&
        difference?.length &&
        difference?.length > 0
      ) {
        store.commit("stepper/storeSearchHistory", { ...filters.value });
      }
    };

    const refindHandler = () => {
      store.commit("stepper/handleSearch", false);
    };

    const selectCity = (items, residence) => {
      set(filters.value.city, residence, items);
    };

    return {
      genders,
      ages,
      countries,
      followersCount,
      engagementRates,
      personReached,
      store,
      formattedAges,
      filters,
      dropdownCities,
      cities,
      colors,
      searchHandler,
      selectCity,
      resetFilters,
      refindHandler,
      isFeedbackActive,
    };
  },
});
