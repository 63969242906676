/* eslint-disable */

const initState = {
    startSearch: false,
    platform: "instagram",
    request_reference: "",
    request_data: {} as any
}

export const engRate = {
    namespaced: true,
    state: initState,
    actions: {},
    mutations: {
        startSearch(state: any, startSearch: boolean) {
            state.startSearch = startSearch
        },
        storePlatfrom(state: any, platform: string) {
            state.platform = platform
        },
        resetState(state: any) {
            state.platform = "instagram" as unknown
            state.startSearch = false
        },
        requestReference(state: any, request_reference: string) {
            state.request_reference = request_reference
        },
        requestData(state: any, request_data: any) {
            state.request_data = request_data
        },

    },

}


