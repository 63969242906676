export const notifications_messages = [
  {
    role: "ADVERTISER",
    messages: [
      {
        notification_module: "CAMPAIGNS",
        notification_type: "ONGOING_CAMPAIGN",
        message_parts: [
          "Your Campaign",
          "_campaign_name_",
          "has started. Track its progress and engage with your influencer(s).",
        ],
        redirection: "/campaign/_campaign_id_",
        image: "campaign_image",
        icon: "campaign_icon",
        color: "orange",
      },
      {
        notification_module: "CAMPAIGNS",
        notification_type: "FINISHED_CAMPAIGN",
        message_parts: [
          "Congratulations! Your campaign",
          "_campaign_name_",
          "is finished. Review its performance insights.",
        ],
        redirection: "/campaign/_campaign_id_/performance",
        image: "campaign_image",
        icon: "campaign_icon",
        color: "green",
      },
      {
        notification_module: "QUOTES",
        notification_type: "ACCEPTED_QUOTE_REQUEST",
        message_parts: [
          "_talent_name_",
          "has accepted your quote request for",
          "_campaign_name_.",
          "View and respond to the quote.",
        ],
        redirection: "/campaign/_campaign_id_/quotes/_quote_id_",
        image: "talent_image",
        icon: "quote_icon",
        color: "green",
      },
      {
        notification_module: "QUOTES",
        notification_type: "ACCEPTED_QUOTE_REQUEST_REMINDER",
        message_parts: [
          "Reminder: You have a pending quote from",
          "_talent_name_.",
          "Review and manage the pending quotes for collaboration opportunities.",
        ],
        redirection: "/campaign/_campaign_id_/quotes/_quote_id_",
        image: "talent_image",
        icon: "quote_icon",
        color: "orange",
      },
      {
        notification_module: "QUOTES",
        notification_type: "DECLINED_QUOTE_REQUEST",
        message_parts: [
          "_talent_name_",
          "has declined your quote request for",
          "_campaign_name_.",
        ],
        redirection: "/campaign/_campaign_id_/quotes",
        image: "talent_image",
        icon: "quote_icon",
        color: "red",
      },
      {
        notification_module: "APPLICATIONS",
        notification_type: "PENDING_APPLICATION",
        message_parts: [
          "New application received!",
          "_talent_name_",
          "has applied for your campaign",
          "_campaign_name_.",
          "Review their application details."
        ],
        redirection: "/campaign/_campaign_id_/applications/_application_id_",
        image: "talent_image",
        icon: "application_icon",
        color: "orange",
      },
      {
        notification_module: "APPLICATIONS",
        notification_type: "PENDING_APPLICATION_REMINDER",
        message_parts: [
          "Reminder: You have pending applications from",
          "_talent_name_",
          "for your campaign",
          "_campaign_name_.",
          "Review and respond before the start date."
        ],
        redirection: "/campaign/_campaign_id_/applications/_application_id_",
        image: "talent_image",
        icon: "application_icon",
        color: "orange",
      },
      {
        notification_module: "APPLICATIONS",
        notification_type: "WITHDRAWN_APPLICATION",
        message_parts: [
          "_talent_name_",
          "has withdrawn your application for your collaboration opportunity",
          "_campaign_name_.",
        ],
        redirection: "/campaign/_campaign_id_/applications",
        image: "talent_image",
        icon: "application_icon",
        color: "red",
      },
      {
        notification_module: "CONTRACTS",
        notification_type: "ACCEPTED_CONTRACT",
        message_parts: [
          "_talent_name_",
          "has accepted your contract for your campaign",
          "_campaign_name_",
        ],
        redirection: "view_contract",
        image: "talent_image",
        icon: "contract_icon",
        color: "green",
      },
      {
        notification_module: "CONTRACTS",
        notification_type: "DECLINED_CONTRACT",
        message_parts: [
          "_talent_name_",
          "has declined your contract for your campaign",
          "_campaign_name_.",
        ],
        redirection: "view_contract",
        image: "talent_image",
        icon: "contract_icon",
        color: "red",
      },
      {
        notification_module: "BRIEFS",
        notification_type: "READ_BRIEF",
        message_parts: [
          "Your brief has been marked as read by",
          "_talent_name_",
          "for your campaign",
          "_campaign_name_.",
        ],
        redirection: "/campaign/_campaign_id_/briefs/_brief_id_",
        image: "talent_image",
        icon: "brief_icon",
        color: "green",
      },
      {
        notification_module: "PLANNING",
        notification_type: "APPROACHING_EVENT_DEADLINE_REMINDER",
        message_parts: [
          "Reminder: Your campaign",
          "_campaign_name_",
          "planning phase ending soon. Ensure all event details are set for a successful collaboration."
        ],
        redirection: "/campaign/_campaign_id_/planning",
        image: "talent_image",
        icon: "planning_icon",
        color: "orange",
      },
      {
        notification_module: "CONTENT_REVIEW",
        notification_type: "NEW_CONTENT_UNDER_REVIEW",
        message_parts: [
          "_talent_name_",
          "has submitted content to review for your campaign",
          "_campaign_name_",
          "Provide feedback for the submitted content."
        ],
        redirection: "/campaign/_campaign_id_/content/_content_id_/event/_event_id_",
        image: "talent_image",
        icon: "content_review_icon",
        color: "green",
      },
      {
        notification_module: "CONTENT_REVIEW",
        notification_type: "NEW_CONTENT_REMINDER",
        message_parts: [
          "Reminder: You have pending content from",
          "_talent_name_",
          "for your campaign",
          "_campaign_name_.",
          "Review and provide feedback or approval."
        ],
        redirection: "/campaign/_campaign_id_/content/_content_id_/event/_event_id_",
        image: "talent_image",
        icon: "content_review_icon",
        color: "orange",
      },
      {
        notification_module: "CONTENT_REVIEW",
        notification_type: "UPDATED_CONTENT_UNDER_REVIEW",
        message_parts: [
          "_talent_name_",
          "has sent new content to review for",
          "_campaign_name_.",
          "Provide feedback for the submitted content."
        ],
        redirection: "/campaign/_campaign_id_/content/_content_id_/event/_event_id_",
        image: "talent_image",
        icon: "content_review_icon",
        color: "orange",
      },
      {
        notification_module: "PERFORMANCE",
        notification_type: "RECEIVED_PERFORMANCE_STATS",
        message_parts: [
          "_talent_name_",
          "has uploaded stats for your event",
          "_event_name_.",
          "Check your performance insights."
        ],
        redirection: "/campaign/_campaign_id_/performance/_event_id_",
        image: "talent_image",
        icon: "performance_icon",
        color: "green",
      },
      {
        notification_module: "PERFORMANCE",
        notification_type: "EDIT_PERFORMANCE_STATS",
        message_parts: [
          "_talent_name_",
          "has updated",
          "_event_name_’s",
          "performance stats. Check them out."
        ],
        redirection: "/campaign/_campaign_id_/performance/_event_id_",
        image: "talent_image",
        icon: "performance_icon",
        color: "orange",
      },
      {
        notification_module: "PERFORMANCE",
        notification_type: "RECEIVED_PERFORMANCE_STATS_REMINDER",
        message_parts: [
          "Reminder: Check received performance stats from",
          "_talent_name_",
          "for your campaign",
          "_campaign_name_."
        ],
        redirection: "/campaign/_campaign_id_/performance/_event_id_",
        image: "talent_image",
        icon: "performance_icon",
        color: "orange",
      },
    ],
  }, {
    role: "TALENT",
    messages: [
      {
        notification_module: "COLLABORATIONS",
        notification_type: "ONGOING_COLLABORATION",
        message_parts: [
          "_brand_name_’s",
          "campaign -",
          "_campaign_name_",
          "has started. Engage with your advertiser and track your campaign's progress.",
        ],
        redirection: "/collaboration/_collaboration_id_",
        image: "campaign_image",
        icon: "collaboraton_icon",
        color: "orange",
      },
      {
        notification_module: "COLLABORATIONS",
        notification_type: "FINISHED_COLLABORATION",
        message_parts: [
          "Congratulations! Your collaboration",
          "_campaign_name_",
          "with",
          "_brand_name_",
          " is finished. You may update your performance stats.",
        ],
        redirection: "/collaboration/_collaboration_id_/performance",
        image: "campaign_image",
        icon: "collaboraton_icon",
        color: "green",
      },
      {
        notification_module: "COLLABORATIONS",
        notification_type: "COLLABORATION_DETAILS_EDIT",
        message_parts: [
          "_brand_name_",
          "has updated",
          "_campaign_name_’s",
          "objectives. Check them out!",
        ],
        redirection: "/collaboration/_collaboration_id_",
        image: "campaign_image",
        icon: "collaboraton_icon",
        color: "orange",
      },
      {
        notification_module: "COLLABORATIONS",
        notification_type: "COLLABORATION_AUDIENCE_EDIT",
        message_parts: [
          "_brand_name_",
          "has updated",
          "_campaign_name_’s",
          "Target Audience. Check it out.",
        ],
        redirection: "/collaboration/_collaboration_id_",
        image: "campaign_image",
        icon: "collaboraton_icon",
        color: "orange",
      },
      {
        notification_module: "COLLABORATIONS",
        notification_type: "COLLABORATION_DELIVERABLES_EDIT",
        message_parts: [
          "_brand_name_",
          "has updated",
          "_campaign_name_’s",
          "deliverables. Check them out!",
        ],
        redirection: "/collaboration/_collaboration_id_",
        image: "campaign_image",
        icon: "collaboraton_icon",
        color: "orange",
      },
      {
        notification_module: "COLLABORATIONS",
        notification_type: "COLLABORATION_DELETE",
        message_parts: [
          "_brand_name_",
          "has deleted the finished campaign",
          "_campaign_name_.",
          "The collaboration history is no longer available.",
        ],
        redirection: "/collaborations",
        image: "campaign_image",
        icon: "collaboraton_icon",
        color: "red",
      },
      {
        notification_module: "COLLABORATIONS",
        notification_type: "COLLABORATION_CANCEL",
        message_parts: [
          "_brand_name_",
          "has canceled its campaign",
          "_campaign_name_.",
          "Stay tuned for future collaboration opportunities.",
        ],
        redirection: "/collaborations",
        image: "campaign_image",
        icon: "collaboraton_icon",
        color: "red",
      },
      {
        notification_module: "COLLABORATIONS",
        notification_type: "REMOVED_TALENT_FROM_COLLABORATION",
        message_parts: [
          "We are sorry!",
          "_brand_name_",
          "has removed you from the campaign",
          "_campaign_name_.",
        ],
        redirection: "/collaborations",
        image: "campaign_image",
        icon: "collaboraton_icon",
        color: "red",
      },
      /////////////
      {
        notification_module: "APPLICATIONS",
        notification_type: "APPLICATION_DECLINED",
        message_parts: [
          "We are sorry! Your application for the collaboration opportunity ",
          "_campaign_name_.",
          "has been declined.",
          
        ],
        redirection: "/my-applications",
        image: "campaign_image",
        icon: "application_icon",
        color: "red",
      },
      {
        notification_module: "APPLICATIONS",
        notification_type: "APPLICATION_ACCEPTED",
        message_parts: [
          "Congratulations! Your application for the collaboration opportunity",
          "_campaign_name_",
          "has been accepted",
        ],
        redirection: "/collaboration/_collaboration_id_",
        image: "campaign_image",
        icon: "application_icon",
        color: "green",
      },
      {
        notification_module: "INVITATIONS",
        notification_type: "INVITATION_PENDING",
        message_parts: [
          "You have received a collaboration invitation from",
          "_brand_name_.",
          "Review the quote request.",
        ],
        redirection: "/invitation/_invitation_id_",
        image: "campaign_image",
        icon: "invitation_icon",
        color: "orange",
      },
      {
        notification_module: "INVITATIONS",
        notification_type: "INVITATION_PENDING_REMINDER",
        message_parts: [
          "Reminder: You have a pending quote from",
          "_brand_name_.",
          "Review and manage the pending quotes for collaboration opportunities.",
        ],
        redirection: "/invitation/_invitation_id_",
        image: "campaign_image",
        icon: "invitation_icon",
        color: "orange",
      },
      {
        notification_module: "INVITATIONS",
        notification_type: "INVITATION_DECLINED",
        message_parts: [
          "We are sorry! Your quote for the collaboration opportunity",
          "_campaign_name_.",
          "has been declined.",
        
        ],
        redirection: "/invitation/_invitation_id_",
        image: "campaign_image",
        icon: "invitation_icon",
        color: "red",
      },
      {
        notification_module: "INVITATIONS",
        notification_type: "INVITATION_ACCEPTED",
        message_parts: [
          "Congratulations! Your quote for the collaboration opportunity",
          "_campaign_name_.",
          "has been accepted.",   
        ],
        redirection: "/collaboration/_collaboration_id_/quote",
        image: "campaign_image",
        icon: "invitation_icon",
        color: "green",
      },
      {
        notification_module: "INVITATIONS",
        notification_type: "INVITATION_WITHDRAWN",
        message_parts: [
          "_brand_name_",
          "has withdrawn the quote request for",
          "_campaign_name_.",
        ],
        redirection: "/invitations",
        image: "campaign_image",
        icon: "invitation_icon",
        color: "red",
      },
      {
        notification_module: "CONTRACTS",
        notification_type: "CONTRACT_PENDING",
        message_parts: [
          "_brand_name_",
          "has sent you a contract to review for the campaign",
          "_campaign_name_.",
        ],
        redirection: "/collaboration/_collaboration_id_/contract",
        image: "campaign_image",
        icon: "contract_icon",
        color: "orange",
      },
      {
        notification_module: "CONTRACTS",
        notification_type: "CONTRACT_PENDING_REMINDER",
        message_parts: [
          "Reminder: You have a pending contract sent by",
          "_brand_name_",
          "for your campaign ",
          "_campaign_name_.",
          "View and respond to your pending contracts.",
        ],
        redirection: "/collaboration/_collaboration_id_/contract",
        image: "campaign_image",
        icon: "contract_icon",
        color: "orange",
      },
      {
        notification_module: "BRIEFS",
        notification_type: "BRIEF_UNREAD",
        message_parts: [
          "_brand_name_",
          "has shared with you the",
          "_campaign_name_'s",
          "brief. Review and mark the brief as read",
        ],
        redirection: "/collaboration/_collaboration_id_/brief",
        image: "campaign_image",
        icon: "brief_icon",
        color: "green",
      },
      {
        notification_module: "BRIEFS",
        notification_type: "BRIEF_UNREAD_REMINDER",
        message_parts: [
          "Reminder: You have an unread campaign brief from",
          "_brand_name_",
          "for the campaign.",
          "_campaign_name_",
          "Review and mark the brief as read.",
        ],
        redirection: "/collaboration/_collaboration_id_/brief",
        image: "campaign_image",
        icon: "brief_icon",
        color: "orange",
      },
      {
        notification_module: "BRIEFS",
        notification_type: "BRIEF_EDIT",
        message_parts: [
          "_brand_name_",
          "has updated",
          "_campaign_name_’s",
          "Brief. Check it out.",
        ],
        redirection: "/collaboration/_collaboration_id_/brief",
        image: "campaign_image",
        icon: "brief_icon",
        color: "orange",
      },
      {
        notification_module: "PLANNING",
        notification_type: "EVENT_CREATED",
        message_parts: [
          "_brand_name_",
          "has created a new event for the campaign",
          "_campaign_name_.",
          "View your event details.",
        ],
        redirection: "/collaboration/_collaboration_id_/planning",
        image: "campaign_image",
        icon: "planning_icon",
        color: "green",
      },
      {
        notification_module: "PLANNING",
        notification_type: "UPCOMING_EVENT_REMINDER",
        message_parts: [
          "Reminder: You have an upcoming deadline for the campaign",
          "_campaign_name_.",
          "Ensure your plans align with the campaign dates.",
        ],
        redirection: "/collaboration/_collaboration_id_/planning",
        image: "campaign_image",
        icon: "planning_icon",
        color: "orange",
      },
      {
        notification_module: "PLANNING",
        notification_type: "EVENT_TITLE_EDIT",
        message_parts: [
          "_brand_name_",
          "has edited",
          "_event_name_’s",
          "title. Check it out.",
        ],
        redirection: "/collaboration/_collaboration_id_/planning",
        image: "campaign_image",
        icon: "planning_icon",
        color: "orange",
      },
      {
        notification_module: "PLANNING",
        notification_type: "EVENT_URL_EDIT",
        message_parts: [
          "_brand_name_",
          "has modified",
          "_event_name_’s",
          "URL. Check it out.",
        ],
        redirection: "/collaboration/_collaboration_id_/planning",
        image: "campaign_image",
        icon: "planning_icon",
        color: "orange",
      },
      {
        notification_module: "PLANNING",
        notification_type: "EVENT_LINK_EDIT",
        message_parts: [
          "_brand_name_",
          "has updated your",
          "_event_name_’s",
          "links. check them out",
        ],
        redirection: "/collaboration/_collaboration_id_/planning",
        image: "campaign_image",
        icon: "planning_icon",
        color: "orange",
      },
      {
        notification_module: "PLANNING",
        notification_type: "EVENT_DESCRIPTION_EDIT",
        message_parts: [
          "_brand_name_",
          "has modified",
          "_event_name_’s",
          "description. Check it out.",
        ],
        redirection: "/collaboration/_collaboration_id_/planning",
        image: "campaign_image",
        icon: "planning_icon",
        color: "orange",
      },
      {
        notification_module: "PLANNING",
        notification_type: "EVENT_DELETE",
        message_parts: [
          "_brand_name_",
          "has deleted",
          "_event_name_.",
          "Please Take this change into consideration.",
        ],
        redirection: "/collaboration/_collaboration_id_/planning",
        image: "campaign_image",
        icon: "planning_icon",
        color: "red",
      },
      {
        notification_module: "CONTENT_APPROVAL",
        notification_type: "CONTENT_ACCEPTED",
        message_parts: [
          "Content approval received! Your content for the campaign",
          "_campaign_name_.",
          "has been validated.",
        ],
        redirection: "/collaboration/_collaboration_id_/content/_content_id_/event/_event_id_",
        image: "campaign_image",
        icon: "content_approval_icon",
        color: "green",
      },
      {
        notification_module: "CONTENT_APPROVAL",
        notification_type: "CONTENT_DEBRIEFED",
        message_parts: [
          "Content feedback received! Your content for the campaign ",
          "_campaign_name_.",
          " has been debriefed. Update your content.",
        ],
        redirection: "/collaboration/_collaboration_id_/content/_content_id_/event/_event_id_",
        image: "campaign_image",
        icon: "content_approval_icon",
        color: "red",
      },
      {
        notification_module: "CONTENT_APPROVAL",
        notification_type: "CONTENT_FEEDBACK_REMINDER",
        message_parts: [
          "Reminder: You have pending content feedback from",
          "_brand_name_",
          "for the campaign",
          "_campaign_name_.",
          "Review the debrief and update your content."
        ],
        redirection: "/collaboration/_collaboration_id_/content/_content_id_/event/_event_id_",
        image: "campaign_image",
        icon: "content_approval_icon",
        color: "red",
      },
      {
        notification_module: "PERFORMANCE",
        notification_type: "PERFORMANCE_STATS_REMINDER",
        message_parts: [
          "Reminder: You have missing content statistics for the event",
          "_event_name_",
          "for the campaign",
          "_campaign_name_.",
          "Upload your stats to showcase your performance."
        ],
        redirection: "/collaboration/_collaboration_id_/performance/_event_id_",
        image: "campaign_image",
        icon: "content_approval_icon",
        color: "red",
      },
    ],
  },
];
