/* eslint-disable */

const initState = {
    free_trial_expire_date : '' as string,
    account_validity_from : '' as string,
    search_count : null as unknown,
    search_request : null as unknown,
    max_search_count : null as unknown,
}

export const quota = {
    namespaced: true,
    state: initState,
    actions: {},
    mutations: {
        free_trial_expire_date(state: any, expire_date: string) {
            state.free_trial_expire_date = expire_date
        },
        account_validity_from(state: any, first_date: string) {
            state.account_validity_from = first_date
        },
        search_count(state: any, search_count: number) {
            state.search_count = search_count
        },
        search_request(state: any, search_request: number) {
            state.search_request = search_request
        },
        max_search_count(state: any, max_search_count: number) {
            state.max_search_count = max_search_count
        },
        
    },
    getters: {
        getFree_trial_expire_date: (state: any) => {
            return state.free_trial_expire_date
        },
        getSearch_count: (state: any) => {
            return state.search_count
        },
        getSearch_request: (state: any) => {
            return state.search_request
        },
        getMax_search_count: (state: any) => {
            return state.max_search_count
        },
        getAccount_validity_from: (state: any) => {
            return state.account_validity_from
        },
    }
}
