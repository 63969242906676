/* eslint-disable */

const initState = {
    shortLists: [],
    shortlists_last_page: 1
}

export const shortList = {
    namespaced: true,
    state: initState,
    actions: {},
    mutations: {
        updateShortLists(state: any, shortLists: any[]) {
            state.shortLists = shortLists
        },
        set_shortlist_last_page(state: any, shortlists_last_page: number) {
            state.shortlists_last_page = shortlists_last_page
        },
    },
    getters: {
        shortlists_last_page: (state: any) => {
            return state.shortlists_last_page
        }
    }

}


