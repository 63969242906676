/* eslint-disable */

const initState = {
    isSearchHandled: false,
    isSearchStarted: false,
    campaigns: [],
    campaignsCount: 0,
    influencerCampaignAF: {
        categories: [] as string[],
        country: '' as string,
        cities: [] as string[],
        start_date: [] as Date[],
        end_date: [] as Date[],
        payment_method: '' as string,
        min: null,
        max: null,
    },
    searchHistory: null as unknown
}

export const campaignFilterStepper = {
    namespaced: true,
    state: initState,
    actions: {
    },
    mutations: {
        storeSearchFilters(state: any, filters: any) {
            state.influencerCampaignAF = filters;
        },
        storeCategories(state: any, categories: string[]) {
            state.influencerCampaignAF.categories = categories;
        },
        storeCountry(state: any, country: string) {
            state.influencerCampaignAF.country = country;
        },
        storeCities(state: any, cities: string[]) {
            state.influencerCampaignAF.cities = cities;
        },
        storeStartdate(state: any, start_date: Date[]) {
            state.influencerCampaignAF.start_date = start_date;
        },
        storeEnddate(state: any, end_date: Date[]) {
            state.influencerCampaignAF.end_date = end_date;
        },
        storeMin(state: any, min: number) {
            state.influencerCampaignAF.min = min;
        },
        storeMax(state: any, max: number) {
            state.influencerCampaignAF.max = max;
        },
        storePaymentMethod(state: any, value: string) {
            state.influencerCampaignAF.payment_method = value
        },
        startSearch(state: any, isSearchStarted: boolean) {
            state.influencerCampaignAF.isSearchStarted = isSearchStarted
        },
        handleSearch(state: any, isSearchHandled: boolean) {
            state.influencerCampaignAF.isSearchHandled = isSearchHandled
        },
        storeSearchHistory(state: any, filters: any) {
            state.influencerCampaignAF.searchHistory = filters;
        },

        resetState(state: any) {
            state.influencerCampaignAF.categories = [] as string[]
            state.influencerCampaignAF.country = null as unknown
            state.influencerCampaignAF.cities = [] as string[]
            state.influencerCampaignAF.start_date = [] as unknown
            state.influencerCampaignAF.end_date = [] as unknown
            state.influencerCampaignAF.min = null
            state.influencerCampaignAF.max = null
            state.influencerCampaignAF.payment_method = ''
            state.searchHistory = null as unknown
            state.campaigns = [] as any[]
            state.campaignsCount = 0;
            state.isSearchHandled = false;
            state.isSearchStarted = false;
        },
        storeCampaigns(state: any, campaigns: any[]) {
            state.influencerCampaignAF.campaigns = campaigns
        },
        storeCampaignsCount(state: any, count: number) {
            state.influencerCampaignAF.campaignsCount = count
        },
        refindState(state: any) {
            state.influencerCampaignAF.isSearchHandled = false
            state.influencerCampaignAF.campaigns = [] as any[]
        }
    },
    getters: {
        getCategories: (state: any) => {
            return state.influencerCampaignAF.categories
        },
        getCountry: (state: any) => {
            return state.influencerCampaignAF.country
        },
        getCities: (state: any) => {
            return state.influencerCampaignAF.cities
        },
        getStartdate: (state: any) => {
            return state.influencerCampaignAF.start_date
        },
        getEnddate: (state: any) => {
            return state.influencerCampaignAF.end_date
        },
        getMin: (state: any) => {
            return state.influencerCampaignAF.min
        },
        getMax: (state: any) => {
            return state.influencerCampaignAF.max
        },
        getPaymentMethod: (state: any) => {
            return state.influencerCampaignAF.payment_method
        },
    }
}
