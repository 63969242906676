import axios from "@/core/services/api";
import store from "@/store/index";

export const getInitData = () => {
    axios
        .get("/category/find")
        .then((res) => {
            if (res.status === 200) {
                store.commit("global/categories", res.data.items);
            }
        })
    axios
        .get("/country/find")
        .then((res) => {
            if (res.status === 200) {
                store.commit("global/countries", res.data.data);
            }
        })
    axios
        .get("/business-sector/find")
        .then((res) => {
            if (res.status === 200) {
                store.commit("global/businessSectors", res.data.items);
            }
        })
}
