
import {defineComponent, ref, watch} from "vue";
import { useStore } from "vuex";
import FollowersCount from "@/components/form/FollowersCount.vue";
import { followersCount } from '@/core/common/steps'

export default defineComponent({
  name: "FollowersCountStep",
  components: {
    FollowersCount
  },
  setup() {
    const store = useStore()
    const state = store.state
    const selectedOptions = ref(state.stepper.searchFilters.followersCount)

    watch(selectedOptions, () => {
      store.commit("stepper/storeFollowersCount", selectedOptions.value)
    }, {
      deep: true
    })

    return {
      followersCount,
      selectedOptions
    }
  }
});
