/* eslint-disable */

const initState = {
    update_channels: false as boolean,
    current_channel_id: '' as string,

}

export const chat = {
    namespaced: true,
    state: initState,
    actions: {},
    mutations: {
        allow_update_channels(state: any) {
            state.update_channels = true
        },
        reset_update_channels(state: any) {
            state.update_channels = false
        },
        set_current_channel_id(state: any, current_channel_id: string) {
            state.current_channel_id = current_channel_id
        },
        reset_current_channel_id(state: any) {
            state.current_channel_id = ''
        },

    },
    getters: {
        get_update_channels: (state: any) => {
            return state.update_channels
        },
        get_current_channel_id: (state: any) => {
            return state.current_channel_id
        },

    }
}
