import axios from "./api";

class UploadFileService {

  upload(file: File) {
    const formData = new FormData();
    formData.append("file", file);
    return axios.post("/media/upload", formData, {
      headers: {
        "Content-Type": "multipart/form-data"
      }

    });
  }
  
  uploadMedia(file: File) {
    const formData = new FormData();
    formData.append("file", file);
    return axios.post("/media/uploadV2", formData, {
      headers: {
        "Content-Type": "multipart/form-data"
      }

    });
  }

}

export default new UploadFileService()