class TokenService {

  getLocalRefreshToken() {
    const item = localStorage.getItem("user") || "";
    if (item) {
      const user = JSON.parse(item);
      return user?.refreshToken;
    }
    return null
  }

  getLocalAccessToken() {
    const item = localStorage.getItem("user") || "";
    if (item) {
      const user = JSON.parse(item);
      return user?.accessToken;
    }

    return null;
  }

  updateLocalAccessToken(token: string) {
    const item = localStorage.getItem("user") || ""
    if (item) {
      const user = JSON.parse(item)
      user.accessToken = token
      localStorage.setItem("user", JSON.stringify(user))
    }
  }

  getUser() {
    const item = localStorage.getItem("user") || "";
    if (item) {
      return JSON.parse(item);
    }
    return null
  }

  getToken() {
    const item = localStorage.getItem("accessToken") || "";
    if (item) {
      return JSON.parse(item);
    }
    return null
  }

  setUser(user: any) {
    localStorage.setItem("user", JSON.stringify(user));
  }

  setToken(user: any) {
    localStorage.setItem("accessToken", JSON.stringify(user));
  }

  setAccess(access: any) {
    localStorage.setItem("access", access);
  }

  removeToken() {
    localStorage.removeItem('accessToken')
  }

  setRole(role: string) {
    localStorage.setItem("role", role);
  }


  getRole() {
    const item = localStorage.getItem("role") || "";
    if (item) {
      return item
    }
    return '';
  }

  getAccess() {
    const item = localStorage.getItem("access") || "";
    if (item) {
      return item
    }
    return '';
  }

  async removeAll() {
    localStorage.clear();
  }

  async removeUser() {
    this.removeToken()
    localStorage.removeItem("user");
  }


}

const tokenService = new TokenService();

export default tokenService


export const getUserToken  = () => {
  return tokenService.getToken();
}
