
import {
  computed,
  defineComponent,
  onMounted,
  onUnmounted,
  ref,
  watch,
  Ref,
} from "vue";
import axios from "@/core/services/api";
import { useStore } from "vuex";
import { genders, countriesPrefixes } from "@/core/common/steps";
import UploadFileService from "@/core/services/upload_file_service";
import Multiselect from "@vueform/multiselect";
import Spinner from "@/components/SpinnerComponent.vue";
import { getFileUrlByToken } from "@/core/helpers";
import useFormValidation from "@/core/validations/useFormValidation";
import { createToaster } from "@meforma/vue-toaster";

export default defineComponent({
  name: "SettingModal",
  components: {
    Spinner,
    Multiselect,
  },
  props: {},
  setup() {
    const store = useStore();
    const activeTab = ref("profile");
    const categories = ref([]);
    const countries = ref([] as any[]);
    const countries_prefixes = ref(countriesPrefixes);
    const cities: Ref<any[]> = ref([]);
    const businessSectors = ref([] as any[]);
    const userInfo = computed(() => store.state.global.userInfo);
    const isAdvertiser = computed(() => (userInfo.value.role === "AGENCY" || userInfo.value.role === "BRAND"));
    // const isAdvertiser = ref(false);
    const isValidated = computed(() => !!userInfo.value.is_validated);
    const currentFile = ref(new File([], ""));
    const picUrl = computed(() => store.state.global.profilePicture);
    const thumbnail = ref("");
    const is_ig_redonly = ref(true);
    const is_tk_redonly = ref(true);
    const originalCountry = ref("" as string)
    const originalCities: { value: never[] } = { value: [] };
    const originalCity = ref("" as string)
    const isUpdateTalentDisabled = ref(true);
    const isUpdateAdvertiserDisabled = ref(true);
    const toaster = createToaster();
    const allow_emails = ref(true);
    const allow_notifications = ref(true);

    const formData = ref({
      // Talent fields
      age_intervale: undefined,
      gender: undefined,
      country_of_origin: undefined,
      username: undefined,
      //Agency fields
      website_url: undefined,
      business_sector_id: undefined,
      // Shared fields
      profile_efile_id: undefined,
      fullname: undefined,
      first_name: undefined,
      last_name: undefined,
      phone: undefined,
      phone_prefix: undefined,
      country: undefined,
      city: undefined,
      cities: [],
      user_email: undefined,
      ig_username: "",
      tiktok_username: "",

    });
    const {
      validateNameField,
      validateUsernameField,
      validatePasswordField,
      validateConfirmPasswordField,
      errors,
    } = useFormValidation();
    const adv_errors = ref({});
    const inf_errors = ref({});
    const pwd_errors = ref({});

    pwd_errors.value = errors;

    if (isAdvertiser.value) {
      adv_errors.value = errors;
    } else {
      inf_errors.value = errors;
    }

    const validateInput = (fieldName: string) => {
      if (fieldName === "ig_username" || fieldName === "tiktok_username") {
        validateUsernameField(fieldName, formData.value[fieldName]);
      } else if (fieldName === "old_password" || fieldName === "new_password") {
        validatePasswordField(fieldName, passwordForm.value[fieldName]);
      } else if (fieldName === "confirm_password") {
        validateConfirmPasswordField(
          passwordForm.value.new_password,
          passwordForm.value.confirmPassword
        );
      } else {
        setTimeout(() => {
          validateNameField(fieldName, formData.value[fieldName]);
        }, 200);
      }
    };
    const successMsg = ref("");
    const errorMsg = ref("");
    const pic_file_id = ref(NaN);
    const passwordForm = ref({
      old_password: null,
      new_password: null,
      confirmPassword: null,
    });
    const showPassword = ref(false);

    const isLoading = ref(false);
    const submitMessage = ref("");

    onMounted(() => {
      categories.value = store.state.global.categories
        ? store.state.global.categories.map((cat) => {
          return { label: cat.label, value: cat.category_id };
        })
        : [];
      countries.value = store.state.global.countries
        ? store.state.global.countries.map((country) => {
          return { label: country.name, value: country.isoCode };
        })
        : [];
      businessSectors.value = store.state.global.businessSectors
        ? store.state.global.businessSectors.map((business_sector) => {
          return {
            label: business_sector.label,
            value: business_sector.business_sector_id,
          };
        })
        : [];
      resetInputs(userInfo.value);
      pic_file_id.value = userInfo.value.profile_efile_id!;
      thumbnail.value = getFileUrlByToken({ token: pic_file_id.value });
      formData.value.profile_efile_id = pic_file_id.value as any;
      if (isAdvertiser.value == true) {
        allow_notifications.value = userInfo.value.advertiser?.allow_notifications == 'Y' ? true : false
        allow_emails.value = userInfo.value.advertiser?.allow_emails == 'Y' ? true : false
      } else {
        allow_notifications.value = userInfo.value.influencer?.allow_notifications == 'Y' ? true : false
        allow_emails.value = userInfo.value.influencer?.allow_emails == 'Y' ? true : false
      }

    });

    onUnmounted(() => {
      successMsg.value = "";
      errorMsg.value = "";
      submitMessage.value = "";
      setTimeout(() => {
        Object.keys(adv_errors.value).forEach((key) => {
          delete adv_errors.value[key];
        });
        Object.keys(inf_errors.value).forEach((key) => {
          delete inf_errors.value[key];
        });
        Object.keys(pwd_errors.value).forEach((key) => {
          delete pwd_errors.value[key];
        });
      }, 500);
    });

    const resetInputs = (data: any) => {
      formData.value.first_name = data.first_name ? data.first_name : null;
      formData.value.last_name = data.last_name ? data.last_name : null;
      formData.value.user_email = data.user_email ? data.user_email : null;
      formData.value.phone = data.phone ? data.phone : null;
      formData.value.phone_prefix = data.phone_prefix ? data.phone_prefix : null;
      formData.value.ig_username = data.ig_username ? data.ig_username : null;
      formData.value.tiktok_username = data.tiktok_username ? data.tiktok_username : null;
      formData.value.gender = data.gender ? data.gender : null;

      if (formData.value.ig_username == null) {
        is_tk_redonly.value = true
        is_ig_redonly.value = false

      }

      if (formData.value.tiktok_username == null) {
        is_ig_redonly.value = true
        is_tk_redonly.value = false
      }

      formData.value.country =
        data.advertiser?.country ||
        data.influencer?.country ||
        data.pre_register?.country ||
        undefined;
      formData.value.country_of_origin =
        data.advertiser?.country_of_origin ||
        data.influencer?.country_of_origin ||
        data.pre_register?.country_of_origin ||
        undefined;

      if (
        data.advertiser?.cities ||
        data.influencer?.city ||
        data.pre_register?.city
      ) {
        setTimeout(() => {
          formData.value.city =
            data.advertiser?.cities[0] ||
            data.influencer?.city ||
            data.pre_register?.city;
          formData.value.cities =
            data.advertiser?.cities
        }, 400);

      } else {
        formData.value.cities.length === 0;
        formData.value.city === 0;
      }
      if (formData.value.country != undefined) {
        originalCountry.value = formData.value.country
      }
      originalCities.value = formData.value.cities
      if (formData.value.city != undefined) {
        originalCity.value = formData.value.city
      }
      if (formData.value.cities || formData.value.city) {
        fetchCitiesOfResidenceCountry();
      }
      if (!isAdvertiser.value) {
        formData.value.fullname =
          data.ig_username || data.tiktok_username || undefined;

        formData.value = {
          ...formData.value,
          ...{
            age_intervale:
              data.pre_register?.age_intervale ||
              data.influencer?.age_intervale ||
              undefined,
            gender:
              data.pre_register?.gender || data.influencer?.gender || undefined,

            influencer_bio:
              data.pre_register?.influencer_bio ||
              data.influencer?.influencer_bio ||
              undefined,
            ig_username: data.ig_username || undefined,
            tiktok_username: data.tiktok_username || undefined,
            categories:
              data.pre_register?.categories ||
              data.influencer?.categories ||
              [],
          },
        };
      } else {
        formData.value.username = data.username || undefined;
        formData.value.fullname =
          data.fullname || data.advertiser.name || undefined;

        formData.value = {
          ...formData.value,
          ...{
            country:
              data.advertiser?.country ||
              data.pre_register?.country ||
              undefined,

            website_url:
              data.advertiser?.website_url ||
              data.pre_register?.website_url ||
              undefined,

            business_sector_id:
              data.advertiser?.business_sector_id ||
              data.pre_register?.business_sector_id ||
              undefined,
          },
        };
      }
    };

    const pushCategoriesIds = (categories: any[]) => {
      if (!isAdvertiser.value) {
        formData.value = {
          ...formData.value,
          ...{
            categories: categories.map((cat) => cat.category_id),
          },
        };
      }
    };

    watch(
      () => formData.value.country,
      () => {
        fetchCitiesOfResidenceCountry();
      }
    );

    const fetchCitiesOfResidenceCountry = () => {
      cities.value = [];
      axios.get(`country/states/${formData.value.country}`).then((res) => {
        if (res.status === 200) {
          const data: any[] = res.data.data;
          data.forEach((city: any) => {
            cities.value.push({
              value: city.isoCode,
              label: city.name,
            });
          });
        }
      });
    };

    const updateProfile = () => {
      if (isAdvertiser.value) {
        validateInput("fullname");
        validateInput("phone");
        validateInput("country");
        validateInput("cities");
        validateInput("business_sector_id");
        if (Object.keys(adv_errors.value).length === 0) {
          // Use extractedInstagramUsername instead of formData.value.ig_username
          formData.value.ig_username = extractedInstagramUsername.value;
          formData.value.city = formData.value.cities[0];
          submitHandler();
        }
      } else {
        const _formData = formData.value as any;
        formData.value.country_of_origin = formData.value.country
        validateInput("first_name");
        validateInput("last_name");
        formData.value.ig_username = extractedInstagramUsername.value;
        validateInput("gender");
        validateInput("country");
        validateInput("phone");
        validateInput("city");
        validateInput("categories");
        validateInput("profile_efile_id");
        if (!_formData.ig_username) {
          validateInput("tiktok_username");
        }

        setTimeout(() => {
          if (Object.keys(inf_errors.value).length == 0) {
            isUpdateAdvertiserDisabled.value = false
            formData.value.ig_username = extractedInstagramUsername.value;
            submitHandler();
          } else {
            toaster.show("Please fill in the required fields.", {
              type: "error",
              position: "bottom",
            });
          }
        }, 200);
      }
    };

    const submitHandler = () => {
      isLoading.value = true;
      axios
        .post("/user/updateMe", formData.value)
        .then(() => {
          isLoading.value = false;
          errorMsg.value = "";
          successMsg.value = "Profile updated successfully !";
          setTimeout(() => {
            window.location.reload();
          }, 500);
        })
        .catch((err) => {
          const data = err.response.data;
          isLoading.value = false;
          successMsg.value = "";
          errorMsg.value = data.error || "Sorry ! Something gone wrong ";
          if (data.messages && data.messages[0] && data.messages[0].metas) {
            errorMsg.value += "by updating your";
          }
          data.messages.forEach((msg) => {
            msg.metas.forEach((meta) => {
              let metaLabel = String(meta);
              switch (meta) {
                case "business_sector_id":
                  metaLabel = "Business sector";
                  break;
                case "city":
                  metaLabel = "City";
                  break;
                case "country":
                  metaLabel = "Country";
                  break;
                // case "country_of_origin":
                //   metaLabel = "Origin country";
                //   break;
                case "firstname":
                  metaLabel = "First name";
                  break;
                case "lastname":
                  metaLabel = "Last name";
                  break;
                case "age_intervale":
                  metaLabel = "Age interval";
                  break;
                case "ig_username":
                  metaLabel = "Instagram username";
                  break;
                case "tiktok_username":
                  metaLabel = "Tiktok username";
                  break;
                case "gender":
                  metaLabel = "Gender";
                  break;
                case "phone":
                  metaLabel = "Number phone";
                  break;
                case "profile_efile_id":
                  metaLabel = "Profile picture";
                  break;
                case "agency_name":
                  metaLabel = "Brand name";
                  break;
                /****************** 
                  DONE
                  *************/
              }
              errorMsg.value += ` ${metaLabel}`;
            });
          });
          errorMsg.value += setTimeout(() => {
            errorMsg.value = "";
          }, 7000);
        });
    };
    // Function to extract social media username
    const extractUsername = (input: string) => {
      // Check if input contains a link, if not, return it as is
      if (
        /https?:\/\/(www\.)?(instagram\.com|tiktok\.com)\/[A-Za-z0-9_.]+/i.test(
          input
        )
      ) {
        const match = input.match(
          /(?:instagram\.com|tiktok\.com)\/([A-Za-z0-9_.]+)/i
        );
        return match ? match[1] : "";
      } else {
        return input;
      }
    };

    // Add computed properties for Instagram and TikTok usernames
    const extractedInstagramUsername = computed(() => {
      return extractUsername(formData.value.ig_username);
    });
    const updatePassword = () => {
      validatePasswordField("old_password", passwordForm.value["old_password"]);
      validatePasswordField("new_password", passwordForm.value["new_password"]);
      validateConfirmPasswordField(
        passwordForm.value.new_password,
        passwordForm.value.old_password
      );
      if (Object.keys(pwd_errors.value).length === 0) {
        isLoading.value = true;
        axios
          .post("/user/updateMe", {
            old_password: passwordForm.value.old_password,
            new_password: passwordForm.value.new_password,
            only_password: true,
          })
          .then(() => {
            isLoading.value = false;
            errorMsg.value = "";
            successMsg.value = "Password is changed successfully !";
            setTimeout(() => {
              successMsg.value = "";
            }, 5000);
          })
          .catch((err) => {
            const data = err.response.data;
            isLoading.value = false;
            successMsg.value = "";
            errorMsg.value = data.error || "Sorry ! Something gone wrong";
            setTimeout(() => {
              errorMsg.value = "";
            }, 5000);
          });
      }
    };

    const updateNotificationsPreferences = () => {
      let the_preferences = {
        allow_notifications: allow_notifications.value,
        allow_emails: allow_emails.value,
        advertiser_id: undefined,
        influencer_id: undefined,
      }
      if (userInfo.value.advertiser && userInfo.value.advertiser_id != null) {
        the_preferences.advertiser_id = userInfo.value.advertiser_id
      } else if (userInfo.value.influencer && userInfo.value.influencer_id != null) {
        the_preferences.influencer_id = userInfo.value.influencer_id
      }
      axios
        .put("/user/notifications-preferences", the_preferences)
        .then(() => {
          isLoading.value = false;
          errorMsg.value = "";
          successMsg.value = "Notifications preferences are changed successfully!";
          setTimeout(() => {
            successMsg.value = "";
            window.location.reload();
          }, 200);
        })
        .catch((err) => {
          const data = err.response.data;
          isLoading.value = false;
          successMsg.value = "";
          errorMsg.value = data.error || "Sorry ! Something gone wrong";
          setTimeout(() => {
            errorMsg.value = "";
          }, 5000);
        });
    };

    const selectFile = (event: any) => {
      currentFile.value = event.target.files.item(0);
      isLoading.value = true;
      UploadFileService.upload(currentFile.value)
        .then((res) => {
          if (res.status === 200) {
            submitMessage.value = "Image Uploaded successfully !";
            isLoading.value = false;
            pic_file_id.value = res.data.data./* _id */file_id;
            formData.value.profile_efile_id = res.data.data./* _id */file_id;
            thumbnail.value = getFileUrlByToken({ token: pic_file_id.value });
            setTimeout(() => {
              submitMessage.value = "";
              store.commit("global/profilePicture", thumbnail.value);
            }, 5000);
          }
        })
        .then(() => {
          if (!isAdvertiser.value) {
            validateInput("profile_efile_id");
          }
        })
        .catch((err) => {
          const data = err.response;
          isLoading.value = false;
          submitMessage.value = data.error || "Sorry ! Something gone wrong";
        });
    };

    const switchTab = (tab: string) => {
      submitMessage.value = "";
      successMsg.value = "";
      errorMsg.value = "";
      activeTab.value = tab;
    };


    watch(
      () => formData.value.country,
      () => {
        if (formData.value.country) {
          fetchCitiesOfResidenceCountry()
          if (formData.value.country !== originalCountry.value) {
            formData.value.cities = []
            formData.value.city = undefined
          } else {
            if (Array.isArray(originalCities.value)) {
              formData.value.cities = originalCities.value;
            } else {
              formData.value.cities = [];
            }
          }
        }
      }
    )


    watch(
      () => Object.keys(inf_errors).length,
      () => {
        if (Object.keys(inf_errors).length != 0) {
          isUpdateTalentDisabled.value = true
        } else {
          isUpdateTalentDisabled.value = false
        }
      }
    )

    return {
      genders,
      picUrl,
      isAdvertiser,
      isValidated,
      activeTab,
      categories,
      countries,
      cities,
      businessSectors,
      formData,
      adv_errors,
      inf_errors,
      pwd_errors,
      passwordForm,
      showPassword,
      isLoading,
      submitMessage,
      successMsg,
      errorMsg,
      pic_file_id,
      thumbnail,
      validateConfirmPasswordField,
      updateProfile,
      switchTab,
      selectFile,
      pushCategoriesIds,
      fetchCitiesOfResidenceCountry,
      submitHandler,
      updatePassword,
      validateInput,
      extractedInstagramUsername,
      countries_prefixes,
      is_ig_redonly,
      is_tk_redonly,
      originalCountry,
      originalCities,
      originalCity,
      updateNotificationsPreferences,
      allow_emails,
      allow_notifications
    };
  },
});
