
import { notifications_messages } from "@/core/common/notifications_messages";
import moment from "moment";
import { computed, defineProps, onBeforeMount, onMounted, ref, unref, defineEmits, watch, defineComponent } from "vue";
import axios from "@/core/services/api";
import { getFileUrlById, getPdfFileUrlByToken } from "@/core/helpers";
import router from "@/router";
import { createToaster } from "@meforma/vue-toaster";
import { useStore } from "vuex";
import formatDuration from "@/core/common/formatDuration";

export default defineComponent({
    name: "NavbarNotificationItem",
    emits: ['update-notifications-count'],
    props: {
        notification: {
            type: Object,
            required: true,
        },
        role: {
            type: String,
            required: true
        },
        notifications_count: {
            type: Number,
            required: true
        }
    },

    setup(props, { emit }) {
        const store = useStore();
        const notifcations_messages = ref([] as any)
        const notification_parts = ref([] as any)
        const notification_assets = ref([] as any)
        const toaster = createToaster();
        const is_read = ref('')
        const minus_notification = ref(props.notifications_count)

        const file_id = ref('')
        const redirection = async () => {
            if (props.notification.notification_status == 'Not read' && is_read.value != 'Read') {
                try {
                    const response = await axios.get(`/notification/${props.notification.notification_hash}/read`)
                    if (response.status == 200) {
                        is_read.value = 'Read'
                        updateNotificationsCount();
                        if (notification_assets.value.redirection == 'view_contract') {
                            const externalUrl = getPdfFileUrlByToken({ token: props.notification?.file?.efile?.token, action: 'view' })
                            const newTab = window.open(externalUrl, "_blank");
                            if (newTab) {
                                newTab.focus();
                            }
                        } else {
                            const link = notification_assets.value.redirection
                                .replace('_campaign_id_', props.notification?.campaign_id)
                                .replace('_quote_id_', props.notification?.module_item_id)
                                .replace('_application_id_', props.notification?.module_item_id)
                                .replace('_brief_id_', props.notification?.module_item_id)
                                .replace('_content_id_', props.notification?.module_item_id)
                                .replace('_event_id_', props.notification?.file?.event_id)
                                .replace('_collaboration_id_', props.notification?.collaboration_id)
                                .replace('_invitation_id_', props.notification?.module_item_id)
                            router.push(link)
                        }
                    }
                }
                catch (error) {
                    toaster.show("Sorry something gone wrong", {
                        type: "error",
                        position: "bottom",
                    });
                }
            } else {
                if (notification_assets.value.redirection == 'view_contract') {
                    const externalUrl = getPdfFileUrlByToken({ token: props.notification?.file?.efile?.token, action: 'view' })
                    const newTab = window.open(externalUrl, "_blank");
                    if (newTab) {
                        newTab.focus();
                    }
                } else {
                    const link = notification_assets.value.redirection
                        .replace('_campaign_id_', props.notification?.campaign_id)
                        .replace('_quote_id_', props.notification?.module_item_id)
                        .replace('_application_id_', props.notification?.module_item_id)
                        .replace('_brief_id_', props.notification?.module_item_id)
                        .replace('_content_id_', props.notification?.module_item_id)
                        .replace('_event_id_', props.notification?.file?.event_id)
                        .replace('_collaboration_id_', props.notification?.collaboration_id)
                        .replace('_invitation_id_', props.notification?.module_item_id)
                    router.push(link)
                }
            }
        };

        const isBold = (part) => {
            return (part != '_campaign_name_'
                && part != '_talent_name_'
                && part != '_brand_name_'
                && part != '_event_name_')
        }

        const combinedContent = () => {
            let content = notification_parts.value.map(part => {

                if (part === '_talent_name_') {
                    return `<b>${props.notification.sender.ig_username ? props.notification.sender.ig_username : props.notification.sender.first_name + " " + props.notification.sender.last_name}</b> &nbsp`;
                } else if (part === '_talent_name_.') {
                    return `<b>${props.notification.sender.ig_username ? props.notification.sender.ig_username : props.notification.sender.first_name + " " + props.notification.sender.last_name}</b> &nbsp`;
                } else if (part === '_brand_name_') {
                    return `<b>${props.notification?.sender?.name}</b> `;
                } else if (part === '_brand_name_.') {
                    return `<b>${props.notification?.sender?.name}</b>. `;
                } else if (part === '_brand_name_’s') {
                    return `<b>${props.notification?.sender?.name}</b>’s `;
                } else if (part === '_campaign_name_.') {
                    return `<b>${props.notification?.campaign?.compain_name}</b>. `;
                } else if (part === '_campaign_name_') {
                    return `<b>${props.notification?.campaign?.compain_name}</b> `;
                } else if (part === '_campaign_name_’s') {
                    return `<b>${props.notification?.campaign?.compain_name}</b>’s `;
                } else if (part === '_event_name_') {
                    return `<b>${props.notification?.file?.event_title}</b> `;
                } else if (part === '_event_name_.') {
                    return `<b>${props.notification?.file?.event_title}</b>. `;
                } else if (part === '_event_name_’s') {                    
                    return `<b>${props.notification?.file?.event_title}</b>’s `;
                } else if (isBold(part)) {
                    return `${part} `;
                }
            }).join('');

            if (content.length > 110) {
                return content = content.slice(0, 110) + '...';
            }

            return content;
        }

        onBeforeMount(() => {
            is_read.value = props.notification?.notification_status
            notifcations_messages.value = notifications_messages.find((message) => message.role == props.role)?.messages || []
            notification_parts.value = notifcations_messages.value.find((message) => message.notification_module == props.notification.notification_module && props.notification.notification_type == message.notification_type)?.message_parts || []
            notification_assets.value = notifcations_messages.value.find((message) => message.notification_module == props.notification.notification_module && props.notification.notification_type == message.notification_type)
            if (notification_assets.value?.image == 'campaign_image') {
                if (typeof props.notification?.campaign?.image_efile_id == 'undefined' || props.notification?.campaign?.image_efile_id == null) {
                    if (props.role == 'TALENT') {
                        file_id.value = props.notification?.sender?.profile_efile_id
                    }
                } else {
                    file_id.value = props.notification?.campaign?.image_efile_id
                }
            }
            else if (notification_assets.value.image == 'talent_image') {
                file_id.value = props.notification?.sender?.profile_efile_id
            }

        })
        const formatDate = (dateTimeString) => {
            const newFormatDate = moment(dateTimeString).endOf('day').fromNow();
            return `${newFormatDate}`;
        };

        const updateNotificationsCount = () => {
            minus_notification.value = props.notifications_count - 1
            emit('update-notifications-count', minus_notification.value);
        };

        store.watch(
            (state) => state.global.read_notification_hash,
            async (data: any) => {
                if (data == props.notification.notification_hash) {
                    is_read.value = 'Read'
                }
            },
            { deep: true }
        );

        return {
            is_read,
            file_id,
            redirection,
            notification_assets,
            combinedContent,
            formatDate,
            updateNotificationsCount,
            formatDuration,
            getFileUrlById
        };
    },
});

