
import {computed, defineComponent, ref, watch} from "vue";
import { useStore } from "vuex";
import { ages } from '@/core/common/steps'
import OptionsInput from "../form/OptionsInput.vue";

export default defineComponent({
  name: "AgeStep",
  components: {
    OptionsInput
  },
  setup() {
    const store = useStore()
    const state = store.state
    const genders = computed(() => state.stepper.searchFilters.gender)
    const formattedAges = computed(() => {
      let _ages: any = []
      if (genders.value && genders.value.length) {
        genders.value.forEach((gender: any) => {
          const _gender = gender === 'M' ? 'man' : 'woman'
          const _genders = ages.map((age: any) => {
            if (age && age.value) {
              return {
                value: `${_gender}-${age.value}`,
                label: age.label,
                icon: `${_gender}_${age.icon}`
              }
            }
          })
          _ages = [..._ages, ..._genders]
        })
      }
      return _ages
    })

    const selectedOptions = ref(state.stepper.searchFilters.age)

    watch(selectedOptions, () => {
      store.commit("stepper/storeAge", selectedOptions.value)
    }, {
      deep: true
    })

    return {
      ages,
      formattedAges,
      selectedOptions
    }
  }
});
