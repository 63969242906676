
import { ref } from "@vue/reactivity";
import { useStore } from "vuex";
import { computed } from "@vue/runtime-core";

export default {
  setup(_, { emit }) {
    // vars
    const store = useStore();
    let is_completed = ref(null);
    let is_validated = ref(null);
    let is_verified = ref(null);
    // computed
    const readIsVerified = computed(() => {
      return store.state.global.isVerified;
    });
    const readIsCompleted = computed(() => {
      return store.state.global.isCompleted;
    });
    const readIsValidated = computed(() => {
      return store.state.global.isValidated;
    });
    //watch isCompleted
    store.watch(
      (state) => state.global.is_completed,
      (data: any) => {
        is_completed.value = data;
      },
      { deep: true }
    );
    //watch isValidated
    store.watch(
      (state) => state.global.is_validated,
      (data: any) => {
        is_validated.value = data;
      },
      { deep: true }
    );
    //watch isVerified
    store.watch(
      (state) => state.global.is_verified,
      (data: any) => {
        is_verified.value = data;
      },
      { deep: true }
    );

    const openSettingsModal = () => {
      emit("update:modelValue", true);
    };

    return {
      readIsCompleted,
      readIsValidated,
      readIsVerified,
      is_completed,
      is_validated,
      is_verified,
      openSettingsModal,
    };
  },
};
