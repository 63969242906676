/* eslint-disable */

const initState = {
    campaign_influencers: [],
    campaign_influencers_avatars: [],
    added_creators_plus_info:[],
    talentsCount: 0,
    addTalentsHistory :null as unknown,
    isAddTalentsHandled: false,
    isAddTalentsStarted: false,
}

export const campaignAdvertiserEdit= {
    namespaced: true,
    state: initState,
    actions: {
    },
    mutations: {
        storeSavedTalents(state: any, influencers: any) {
            state.campaign_influencers = influencers;
        },
        storeAddedCreatorsPlusInfo(state: any, creators: any) {
            state.added_creators_plus_info = creators;
        },
        storeSavedAvatars(state: any, avatars: any) {
            state.campaign_influencers_avatars = avatars;
        },
        storeAddTalentsHistory(state: any, influencers: any) {
            state.addTalentsHistory = influencers;
        },
        handleAddTalents(state: any, isAddTalentsHandled: boolean) {
            state.isAddTalentsHandled = isAddTalentsHandled
        },
        resetState(state: any) {
            state.campaign_influencers = [] as any[]
            state.campaign_influencers_avatars = [] as any[]
            state.added_creators_plus_info = [] as any[]
            state.addTalentHistory = null as unknown
            state.talentsCount = 0;
            state.isAddTalentsHandled = false;
            state.isAddTalentsStarted = false;
        },
        refindState(state: any) {
            state.isAddTalentsHandled = false
            state.campaign_influencers = [] as any[]
            state.campaign_influencers_avatars = [] as any[]
            state.added_creators_plus_info = [] as any[]
        }
    },
    getters: {
        getTalents: (state: any) => {
            return state.campaign_influencers
        },
        getAvatars: (state: any) => {
            return state.campaign_influencers_avatars
        },
    }
}
