import { AbilityBuilder, Ability, AbilityClass } from '@casl/ability';
import store from "@/store";

type Actions = 'manage' | 'read' | 'create' | 'delete';
type Subjects = 'Profile' | 'Discover' | 'Talent' | 'Favorite' | 'CalculateEngagementRate' | 'CampaignManagement';

export type AppAbility = Ability<[Actions, Subjects]>;
export const AppAbility = Ability as AbilityClass<AppAbility>;

const userRules = (can: any) => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    if (store.state && store.state.global && store.state.global.rules) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        store.state.global.rules.forEach((rule: any) => {
            can(rule.action, rule.subject);
        });
    }
};

export function defineRulesFor() {
    const { can, rules } = new AbilityBuilder(AppAbility);
    userRules(can);
    return rules;
}

export function buildAbilityFor() {
    return new AppAbility(defineRulesFor(), {
        /**
         * Read for details: https://casl.js.org/v5/en/guide/subject-type-detection
         */
        detectSubjectType: (object: any) => object!.kind,
    });
}

export const ability = buildAbilityFor();

