import { register } from 'register-service-worker';

let isUpdatePromptShown = false;

if (process.env.NODE_ENV === 'production' || process.env.NODE_ENV === 'staging' || process.env.NODE_ENV === 'development') {
  register(`${process.env.BASE_URL}service-worker.js`, {
    ready() {
      // Service Worker is ready.
    },
    registered() {
      // Service Worker has been registered.
    },
    cached() {
      // Content has been cached for offline use.
    },
    updatefound() {
      // New content is downloading.
    },
    updated(registration) {
      if (!isUpdatePromptShown && !localStorage.getItem('cacheCleared')) {
        isUpdatePromptShown = true;
        console.log('updated')
        showUpdateConfirmation(registration);
      }
    },
    offline() {
      // No internet connection found. App is running in offline mode.
    },
    error(error) {
      console.error('Error during service worker registration:', error);
    }
  });
} else {
  console.log('Service worker not registered, current environment:', process.env.NODE_ENV);
}

// Function to show the update confirmation dialog
function showUpdateConfirmation(registration: ServiceWorkerRegistration) {
  // const userConfirmed = confirm("A new version of TAWA DIGITAL TALENTS is available. Click OK to be up to date!");
  // if (userConfirmed) {
  //   clearCacheAndReload(registration);
  // }
  alert("A new version of TAWA DIGITAL TALENTS is available. Click OK to be up to date!");
  clearCacheAndReload(registration);
}

// Function to clear the cache and reload the app
function clearCacheAndReload(registration: ServiceWorkerRegistration) {
  if (registration.waiting) {
    registration.waiting.postMessage({ type: 'SKIP_WAITING' });
  }

  caches.keys().then(names => {
    return Promise.all(names.map(name => caches.delete(name)));
  }).then(() => {
    localStorage.setItem('cacheCleared', 'true');  // Set the flag to prevent looping
    window.location.reload(); // Forces a reload from the server
  });
}

// Optional: Clear the flag when the Service Worker is fully activated, ensuring the next update works as expected
navigator.serviceWorker.addEventListener('controllerchange', () => {
  localStorage.removeItem('cacheCleared');
});
